import { apiClient } from '@/plugins/api'

export default {
  changeCampaignStatus(companyId, campaignId, status) {
    let payload = { status: status }
    return apiClient.put(`phishing/company/${companyId}/campaign/${campaignId}/status`, payload)
  },
  changeCampaignSuspendStatus(companyId, campaignId, status) {
    let payload = { status: status }
    return apiClient.put(`phishing/company/${companyId}/campaign/${campaignId}/suspend`, payload)
  },
  getCampaignTemplates(companyId, campaignId, isIncluded) {
    let opt = { params: { status: isIncluded } }
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/template`, opt)
  },
  getCampaignTemplateById(companyId, campaignId, templateId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/template/${templateId}`)
  },
  searchTemplates(companyId, campaignId, searchText, searchStatus) {
    let opt = {
      params: {
        searchText: searchText,
        searchStatus: searchStatus
      }
    }
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/template/search`, opt)
  },
  changeTemplateStatus(companyId, campaignId, templateId, statusValue) {
    let payload = { status: statusValue }
    return apiClient.put(`phishing/company/${companyId}/campaign/${campaignId}/template/${templateId}/status`, payload)
  },
  testAttack(templateCompanyId, campaignId, templateId, targetEmail, targetName, targetNumber, templateType, previewLanguage, companyId, singleLanguage) {
    const payload = {
      target_email: targetEmail,
      target_name: targetName,
      target_number: targetNumber,
      template_type: templateType,
      language: previewLanguage,
      company_id: companyId,
      single_language: singleLanguage
    }
    return apiClient.post(`phishing/company/${templateCompanyId}/campaign/${campaignId}/template/${templateId}/test`, payload)
  },
  updateCampaignDates(companyId, campaignId, dates) {
    return apiClient.put(`phishing/company/${companyId}/campaign/${campaignId}/dates`, dates)
  },
  updateRemediationTemplates(companyId, campaignId, templates) {
    let payload = { templates: templates }
    return apiClient.post(`phishing/company/${companyId}/campaign/${campaignId}/remediation/templates`, payload)
  },
  initializeRemediationCampaign(companyId, campaignId, campaignDuration, targets, type) {
    let payload = {
      companyId: companyId,
      duration: campaignDuration,
      targets: targets,
      type: type
    }
    return apiClient.post(`phishing/company/${companyId}/campaign/${campaignId}/remediate`, payload)
  },
  getCompletedRemediationTargets(companyId, campaignId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/remediation/targets`)
  },
  getCompletedRemediationClicks(companyId, campaignId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/remediation/clicks`)
  },
  getCompletedRemediationTemplates(companyId, campaignId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/remediation/templates`)
  },
  readyToApprove(companyId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/ready`)
  },
  getRunningTestCampaigns(companyId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/test`)
  },
  getCampaign(companyId, campaignId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}`)
  },
  deleteCampaign(companyId, campaignId) {
    return apiClient.delete(`phishing/company/${companyId}/campaign/${campaignId}`)
  },
  completeCampaign(companyId, campaignId) {
    return apiClient.put(`phishing/company/${companyId}/campaign/${campaignId}/complete`);
  },
  wipeoutCampaign(companyId, campaignId) {
    return apiClient.delete(`phishing/company/${companyId}/campaign/${campaignId}/wipeout`);
  },
  getRunningTestCampaignData(companyId, campaignId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/test/${campaignId}`);
  },
  getCurrentCoverage(companyId, campaignId) {
    return apiClient.post(`phishing/company/${companyId}/campaign/${campaignId}/coverage`);
  },
  remakeCampaign(companyId, campaignId) {
    let payload = { companyId: companyId }
    return apiClient.post(`phishing/company/${companyId}/campaign/${campaignId}/remake`, payload)
  },
  getCampaignReport(companyId, campaignId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/report`, opt)
  },
  getCampaignActiveData(companyId, campaignId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/active`, opt)
  },
  getCampaignStats(companyId, campaignId, queryParams) {
    let opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/stats`, opt)
  },
  getAssignedTargetNumber(companyId, campaignId) {
    return apiClient.get(`phishing/company/${companyId}/campaign/${campaignId}/assigned_targets_number`)
  },
  assignRandomTemplates(companyId, campaignId) {
    return apiClient.post(`phishing/company/${companyId}/campaign/${campaignId}/assign_random_templates`)
  }
}
