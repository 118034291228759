<template> 
    <font-awesome-icon :icon="obj.icon" :title="obj.title"></font-awesome-icon>
</template>
  
<script>
import { defineComponent } from 'vue';

import { Status } from '@/common/constants';

export default defineComponent({
  props: {
      status: {
          type: String,
          default: 'Pending',
      },
      username: {
          type: String, 
          default: '',
      },
  },

  computed: {
      obj() {
          let temp = {};
          switch(this.status?.toLowerCase()) {
              case Status.Active:
                  temp = {icon: ['fas', 'user-check'], title: 'Account.AccountActivationSuccess'};
                  break;
              case Status.RegisterError:
                  temp = {icon: ['fas', 'user-xmark'], title: 'Account.AccountActivationRegisterError'};
                  break;
              case Status.Suspended:
                  temp = {icon: ['fas', 'user-slash'], title: 'Account.AccountSuspended'};
                  break;
              case Status.Pending:
              default:
                  temp = {icon: ['fas', 'clock-rotate-left'], title: 'Account.AccountActivationPending'};
          }
          return {icon: temp.icon, title: this.$t(temp.title, {param: this.username})};
      }
  },
});
</script>
