<template>
  <div
    :class="{
      'page-wrapper-contextual': true,
      'v-padding': contextualPadding,
      'p-left': contextualPosition === 'left',
      'p-right': contextualPosition === 'right',
      'bg-white': contextualBackground === 'white',
      'bg-extra-light': contextualBackground === 'light',
    }"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    contextualBackground: {
      type: String,
      default: 'white', // 'white' | 'light'
    },
    contextualPosition: {
      type: String,
      default: 'right', // 'left' | 'right'
    },
    contextualPadding: {
      type: Boolean,
      default: false
    }
  },
});
</script>

<style lang="scss" scoped>
.page-wrapper-contextual {
  @apply flex-shrink-0;
  @apply order-1;
  @apply mb-6 md:mb-8 lg:mb-0;
  @apply lg:pt-8 xl:pt-19;

  @screen lg {
    @apply h-full w-[30%];

    &.p-left {
      @apply order-1 max-w-xs;
    }
    &.p-right {
      @apply order-3 max-w-md;
    }
  }

  &.v-padding {
    @apply p-6 lg:p-8;
    @apply lg:pb-0 xl:pb-0;
  }
}
</style>
