<template>
  <div
    :class="{
      radio: true,
      disabled,
    }"
  >
    <span class="radio-check" tabindex="0" @click="toggleChecked" @keydown.enter="toggleChecked">
      <input
        :id="id"
        class="radio-input"
        :checked="inputValue && isChecked"
        type="radio"
        :name="name"
        :value="inputValue"
        :disabled="disabled"
        tabindex="-1"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <div class="radio-icon">
        <span class="radio-dot" />
      </div>
    </span>
    <span class="radio-text" :for="id" @click="toggleChecked">
      <slot />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['update:modelValue'],

  props: {
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: String,
      default: undefined,
    },
    inputValue: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
  },

  computed: {
    isChecked() {
      return this.modelValue === this.inputValue;
    },
  },

  methods: {
    toggleChecked() {
      if (this.disabled) return

      this.$emit('update:modelValue', this.inputValue)
    },
  },
});
</script>

<style lang="scss" scoped>
.radio {
  @apply inline-flex items-start;
  @apply text-sm;

  &:hover:not(.disabled) {
    .radio-input:not(:checked) + .radio-icon {
      @apply border-soft-blue-700;
    }
  }

  &.disabled {
    > * {
      @apply cursor-not-allowed;
      @apply text-primary-300;
    }
  }
}

.radio-check {
  @apply shrink-0;
  @apply flex items-center;
  height: calc(1em * 1.25);
}

.radio-icon {
  @apply block text-2xl;
  @apply relative w-5 h-5 rounded-full pointer-events-none;
  @apply border border-primary-300;
  @apply bg-white text-transparent;
  @apply transition-colors duration-150 ease-in-out;
}

.radio-dot {
  @apply block;
  @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  @apply w-3 h-3 rounded-full pointer-events-none;
  @apply bg-soft-blue-700;
  @apply opacity-0 scale-50;
  @apply transition duration-300 ease-in-out;

  .disabled & {
    @apply bg-primary-300;
  }
}

.radio-input {
  @apply sr-only;

  &:checked + .radio-icon {
    .radio-dot {
      @apply opacity-100 scale-100;
    }
  }
}

.radio-text {
  @apply flex-grow;
  @apply ml-2;
  @apply cursor-default;
  font-size: inherit;
  line-height: inherit;
}
</style>
