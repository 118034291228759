<template>
  <div
    role="document"
    :class="{
      card: true,
      disabled,
    }"
  >
    <span v-if="contentDescription" class="sr-only">{{ contentDescription }}</span>
    <span v-if="text" class="card-product-name" :style="disabled ? '' : { backgroundColor: `${bgColor}` }">{{ text }}</span>
    <div v-if="thumbnailUrl" class="card-thumbnail">
      <div class="card-thumbnail-img" :style="{ backgroundImage: `url(${thumbnailUrl})` }" />
    </div>
    <div class="card-content" :class="[{ unstyled: unstyled === true }, customClass]" :style="customStyle">
      <CGLink v-if="to && !disabled" :to="to" class="card-link" variant="unstyled">
        <slot />
      </CGLink>
      <slot v-else />
    </div>
    <div v-if="$slots.accessory" class="card-accessory">
      <slot name="accessory" />
    </div>
    <div v-if="$slots.footer" class="card-footer" :class="footerCustomClass">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({

  props: {
    to: {
      type: String,
      default: undefined,
    },
    thumbnailUrl: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unstyled: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: undefined,
    },
    footerCustomClass: {
      type: String,
      default: undefined
    },
    customStyle: {
      type: [String, Object],
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    contentDescription: {
      type: String,
      default: undefined
    },
    bgColor: {
      type: String,
      default: undefined,
    }
  },
});
</script>

<style lang="scss" scoped>
.card {
  @apply flex-1 flex flex-col relative;
  @apply h-full;
  @apply border border-primary-100;
  @apply bg-white;

  &.disabled {
    @apply bg-gray-300;

    &,
    > * {
      @apply cursor-not-allowed;
    }
  }
}

.card-product-name {
  @apply text-xs font-extrabold uppercase text-white bg-black;
  @apply absolute flex items-center h-5 pt-px px-1 z-[1];
  @apply ml-4 mt-4;
  @apply bg-custom-primary;
  @apply text-custom-contrastPrimary;
}

.card-thumbnail {
  @apply relative w-full pb-[40%];
}

.card-thumbnail-img {
  @apply absolute inset-0 w-full h-full;
  @apply bg-center bg-cover bg-no-repeat;
}

.card-content {
  @apply flex flex-1 flex-col justify-between;
}

.card-content,
.card-footer {
  @apply p-4;
  &.unstyled {
    @apply pb-0;
  }
}

.card-accessory {
  @apply absolute right-2 top-2 z-10;
  @apply flex;

  .card-thumbnail ~ & {
    @apply top-0 right-4;
    @apply mt-[40%] -translate-y-1/2;
  }
}

.card-footer {
  @apply border-t border-primary-100;
}

.card-link {
  @apply after:absolute after:inset-0;
}
</style>

<style lang="scss" global>
.card {
  &.disabled {
    button {
      @apply pointer-events-none grayscale;
    }

    .card-content,
    .card-footer {
      > * {
        @apply text-gray-600;
      }
    }
  }
}
</style>
