<template>
  <li
    class="page-nav-item"
    :class="{
      'disabled': !to,
      active
    }"
    @click="$emit('click')"
  >
    <component
      :is="to ? 'Link' : 'span'"
      v-if="to"
      :class="to ? 'page-nav-item-link' : undefined"
      :variant="to ? 'unstyled' : undefined"
      :to="to"
    >
      <slot />
    </component>
    <Txt v-if="!to"><slot /></Txt>
  </li>
</template>

<script>
import { defineComponent } from 'vue';

import Link from './Link.vue'

export default defineComponent({
  components: { Link },

  props: {
    to: {
      type: String,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],
});
</script>

<style lang="scss" scoped>
.page-nav-item {
  @apply relative;
  @apply text-sm uppercase leading-none whitespace-nowrap;
  @apply text-primary-300;
  @apply cursor-pointer;
  @apply transition-colors duration-300 ease-in-out;
  //@apply hover:text-violet-700;
  @apply hover:text-primary-700;

  &.active {
    //@apply text-violet-700 font-bold;
    @apply text-primary-700 font-bold;

    &::after {
      content: '';
      @apply block absolute top-full left-0 h-0.5 w-[18px];
      //@apply bg-accent-500;
      @apply bg-custom-primary;
    }
  }

  &.disabled {
    @apply text-primary-300;
    @apply cursor-default;
  }

  > * {
    @apply block py-1;
  }
}

.page-nav-item-link {
  color: inherit;
  //@apply hover:text-violet-700;
  @apply hover:text-primary-700;
}
</style>
