<template>
  <tr
    :class="{
      'table-row': true,
      'v-link': to,
    }"
  >
    <TableCell v-if="selectable">
      <Checkbox v-if="!disableSelection" :modelValue="isSelected()" @update:modelValue="toggleSelected" />
    </TableCell>
    <slot />

    <div @click="onClick">
      <Icon v-if="to" name="chevronRight" class="table-row-indicator" />
    </div>
  </tr>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    record: {
      type: [Object, String, Number],
      required: true,
    },
    to: {
      type: String,
      default: undefined,
    },
    disableSelection: {
      type: Boolean,
      default: false,
    },
    isSelectedCallback: {
      type: Function,
      required: false, // Optional callback for external management
    },
    toggleSelectionCallback: {
      type: Function,
      required: false, // Optional callback for external management
    },
  },

  data() {
    return {
      selectable: this.isSelectable()
    }
  },

  computed: {
    isUsingCallbacks() {
      // Check if the component should use callbacks for selection logic
      return this.isSelectedCallback && this.toggleSelectionCallback;
    },
  },

  methods: {
    isSelectable() {
      return this.$parent.$props.selectable;
    },
    isSelected() {
      if (this.isUsingCallbacks) {
        // Use the callback to check if the row is selected
        return this.isSelectedCallback(this.record);
      }
      // Fallback to parent-provided `selectedRecords`
      if (!this.selectable || !this.$parent.$props.selectedRecords) return false;
      return this.$parent.$props.selectedRecords.includes(this.record);
    },
    toggleSelected(checked) {
      if (this.isUsingCallbacks) {
        // Use the callback to toggle the selection state
        this.toggleSelectionCallback(this.record, checked);
      } else {
        // Fallback to directly modifying `selectedRecords`
        if (!this.$parent.$props.selectedRecords) return;

        let selectedRecords;
        if (this.$parent.$props.singleSelection && checked) {
          selectedRecords = [this.record];
        } else {
          selectedRecords = [...this.$parent.$props.selectedRecords];
          const index = selectedRecords.indexOf(this.record);

          if (checked && index === -1) {
            selectedRecords.push(this.record);
          } else if (!checked && index !== -1) {
            selectedRecords.splice(index, 1);
          }
        }
        this.$parent.$emit('update:selectedRecords', selectedRecords);
      }
    },
    onClick() {
      if (!this.to) return;
      if (!this.$router) return;
      this.$router.push(this.to);
    },
  },
});
</script>

<style lang="scss" scoped>
.table-row {
  @apply relative;

  &.v-link {
    @apply cursor-pointer;
    @apply transition-colors duration-200 ease-in-out;
    @apply hover:bg-soft-blue-300;

    .table-cell:last-of-type {
      @apply pr-8;
    }

    .table-row-indicator {
      @apply text-2xl text-primary-300;
      @apply absolute right-1 top-1/2 -translate-y-1/2;
    }
  }
}
</style>

<style lang="scss" global>
.table.v-alternate-light {
  .table-row.v-link:nth-child(odd) {
    @apply hover:bg-soft-blue-300;
  }
}
</style>
