<template>
  <div
    v-if="open"
    ref="modalContainerEl"
    :class="{
      modal: true,
      open,
      closing: !open,
      's-small': small,
      's-expanded': expanded || fullWidth,
      's-full-height': fullHeight,
    }"
    role="dialog"
    aria-modal="true"
    :tabindex="open ? -1: 0"
    aria-live="assertive"
    :aria-label="title"
    :aria-hidden="!open"
    @keydown.tab="handleTab"
    @keydown.esc="$emit('update:open', false)"
  >
    <div class="modal-overlay" />

    <div
      ref="dialogEl"
      class="modal-dialog"
      :style="{
        width: expanding ? `${maxWidth}px` : compressing ? `${initialSize[0]}px` : undefined,
        height: expanding ? `${maxHeight}px` : compressing ? `${initialSize[1]}px` : undefined,
        maxWidth: expanding ? '100%' : compressing ? 'auto' : undefined,
        maxHeight: expanding ? '100%' : compressing ? 'auto' : undefined,
      }"
    >
      <header
        :class="{
          'modal-header': true,
          'a-left': headerAlignment === 'left',
          'a-center': headerAlignment === 'center',
          'a-right': headerAlignment === 'right',
        }"
      >
        <div class="modal-header-actions">
          <CGButton v-if="withExpandButton" variant="ghost" :aria-label="$t('Wcag.' + (expanded ? 'Compress' : 'Expand') + 'Modal')" @click="toggleExpanded">
            <Icon :name="expanded ? 'compress' : 'expand'" />
          </CGButton>
          <CGButton v-if="withCloseButton" class="modal-close" :aria-label="$t('General.Close')" variant="outline" @click="$emit('update:open', false)">
            <Icon name="close" />
          </CGButton>
        </div>
        <div v-if="iconName" class="modal-icon">
          <Icon :name="iconName" />
        </div>
        <Txt v-if="title" as="h3" class="modal-title break-words" no-margin :class="{ 'text-center' : titleCentered }">{{ title }}</Txt>
        <Txt v-if="description" class="modal-description" small no-margin>{{ description }}</Txt>
      </header>
      <div class="modal-body">
        <div class="modal-content">
          <slot />
        </div>
      </div>
      <footer
        v-if="footerVisible"
        :class="{
          'hidden': !footerVisible,
          'modal-footer': true,
          'c-light': footerBackground === 'light',
          'a-left': footerAlignment === 'left',
          'a-center': footerAlignment === 'center',
          'a-right': footerAlignment === 'right',
        }"
      >
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default defineComponent({
  emits: ['update:open'],

  model: {
    prop: 'open',
    event: 'update:open',
  },

  props: {
    title: {
      type: [String],
      default: undefined,
    },
    titleCentered: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: undefined,
    },
    iconName: {
      type: String,
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    withCloseButton: {
      type: Boolean,
      default: false,
    },
    withExpandButton: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    headerAlignment: {
      type: String, // 'left' | 'center' | 'right'
      default: undefined,
    },
    footerAlignment: {
      type: String, // 'left' | 'center' | 'right'
      default: undefined,
    },
    footerBackground: {
      type: String, // 'none' | 'light'
      default: 'none',
    },
    footerVisible: {
      type: Boolean,
      default: true
    },
    trigger: {
      default: null
    },
  },

  data() {
    return {
      expanded: false,
      expanding: false,
      compressing: false,
      closing: false,
      initialSize: undefined,
    }
  },

  computed: {
    maxWidth() {
      const containerStyle = getComputedStyle(this.$refs.modalContainerEl)
      return document.documentElement.clientWidth - parseInt(containerStyle.padding) * 2
    },
    maxHeight() {
      const containerStyle = getComputedStyle(this.$refs.modalContainerEl)
      return document.documentElement.clientHeight - parseInt(containerStyle.padding) * 2
    },
  },

  watch: {
    open(isOpen) {
      if (isOpen) {
        nextTick(() => {
          disableBodyScroll(this.$refs.modalContainerEl)
          this.$refs.modalContainerEl.focus();
        });
      } else {
        enableBodyScroll(this.$refs.modalContainerEl)

        this.closing = true
        this.$refs.dialogEl.addEventListener(
          'transitionend',
          () => {
            this.closing = false
          },
          { once: true }
        )
      }
    },
    trigger(t) {
      // update trigger focus and aria-haspopup attribute
      try {
        const trigger = (Array.isArray(t))? t[0] : t;

        if (trigger instanceof HTMLDivElement) {
          if (!this.open) trigger.focus();
          trigger.setAttribute('aria-haspopup', 'dialog');
        } else {
          if (!this.open) trigger.$el.focus();
          trigger.$el.setAttribute('aria-haspopup', 'dialog');
        }
      } catch (error) {
        console.log("Modal trigger element not assigned", this.trigger);
      }
    }
  },

  mounted() {
    if (this.open) {
      this.$refs.modalContainerEl.focus();
    }
  },

  methods: {
    handleTab(event) {
      const focusableElements = this.$refs.dialogEl.querySelectorAll(
        'input:not([disabled]), button:not([disabled]), a:not([disabled]), [href]:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled])'
      );

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];
      
      if (event.shiftKey && document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      } else if (!event.shiftKey && document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    },
    toggleExpanded() {
      const expand = !this.expanded

      if (expand) {
        this.initialSize = [this.$refs.dialogEl.clientWidth, this.$refs.dialogEl.clientHeight]
      }

      if (expand) {
        this.expanding = true
      } else {
        this.compressing = true
      }

      this.$refs.dialogEl.addEventListener(
        'transitionend',
        () => {
          this.expanded = expand
          setTimeout(() => {
            this.expanding = false
            this.compressing = false
          }, 500)
        },
        { once: true }
      )
    },
  },
});
</script>

<style lang="scss" scoped>
.modal {
  @apply fixed inset-0;
  @apply opacity-0 -z-10 pointer-events-none;
  @apply flex justify-center md:items-center;
  @apply p-5 md:p-10;

  &.open,
  &.closing {
    @apply z-50;
    @apply opacity-100 pointer-events-auto;
  }
}

.modal-overlay {
  @apply fixed inset-0;
  @apply bg-primary-500/0 backdrop-blur-none;
  transition-property: background-color, backdrop-filter;
  @apply duration-200 ease-in-out;

  .open & {
    @apply bg-primary-500/30 backdrop-blur-sm;
  }

  .closing & {
    @apply delay-100;
  }
}

.modal-dialog {
  @apply relative flex flex-col;
  @apply w-[95vw] max-w-screen-md max-h-[60rem] overflow-visible;
  @apply bg-white shadow-md shadow-primary-300/50;
  @apply mx-auto;
  @apply opacity-0 -translate-y-6;
  transition-property: opacity, transform, width, height, max-width, max-height;
  @apply duration-300 ease-in-out delay-100;

  .open & {
    @apply opacity-100 translate-y-0;
  }

  .closing & {
    @apply opacity-0 -translate-y-6 delay-[0];
  }

  .s-small & {
    @apply max-w-lg;
  }

  .s-expanded & {
    @apply max-w-none w-full h-full max-h-full;
  }

  .s-full-height & {
    @apply h-full max-h-full;
  }
}

.modal-header,
.modal-body,
.modal-footer {
  @apply px-6 md:px-12;
  @apply py-3 md:py-5;
}

.modal-footer {
  @apply mt-10;
  @apply pt-0;
}

.modal-header {
  @apply flex flex-col pb-0;

  &.a-left {
    @apply sm:items-start;
  }

  &.a-center {
    @apply sm:items-center;
  }

  &.a-right {
    @apply sm:items-end;
  }
}

.modal-header-actions {
  @apply flex items-center justify-end space-x-5;
  @apply self-stretch;

  .modal-close {
    @apply w-8 h-8 px-0;
    @apply border-primary-300;
  }
}

.modal-icon {
  @apply relative mt-4;
  @apply w-16 h-16 md:w-24 md:h-24;
  @apply bg-soft-blue-100 rounded-full;
  @apply text-soft-blue-700;

  .icon {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
    @apply text-4xl md:text-6xl;
  }
}

.modal-title {
  @apply mt-8;
}

.modal-description {
  @apply text-opacity-70;
  @apply mt-4;
}

.modal-body {
  @apply flex-1 flex flex-col;
  @apply overflow-hidden;
  max-height: calc(100vh - 250px);

  *::-webkit-scrollbar {
    width: 0.5rem !important;
  }
  *::-webkit-scrollbar-track {
    @apply bg-white;
  }
  *::-webkit-scrollbar-thumb {
    @apply bg-gray-300 rounded;
  }
}

.modal-content {
  @apply w-full h-full;
  @apply overflow-x-hidden overflow-y-auto;

  .page-header {
    @apply mb-0;
  }
}

.modal-footer {
  @apply flex flex-col space-y-3;
  @apply sm:flex-row sm:space-y-0 sm:space-x-3;

  &.c-light {
    @apply bg-soft-blue-100;
  }

  &.a-left {
    @apply sm:justify-start;
  }

  &.a-center {
    @apply sm:justify-center;
  }

  &.a-right {
    @apply sm:justify-end;
  }

  &.hidden {
    height: 4rem;
    button {
      display: none;
    }
  }

}
</style>
