<template>
  <div
    :class="{
      'circular-progress': true,
      'c-violet': color === 'violet',
      'c-orange': color === 'orange',
      'c-acid': color === 'acid',
      'c-green': color === 'green',
      'c-blue': color === 'blue',
      'c-red': color === 'red',
    }"
    :style="{
      width: size ? `${size}px` : undefined,
      height: size ? `${size}px` : undefined,
    }"
  >
    <svg
      class="circular-progress-indicator"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
    >
      <g :transform="`translate(${radius + trackWidth / 2}, ${radius + trackWidth / 2})`">
        <circle
          class="circular-progress-circle-track"
          fill="transparent"
          :stroke-width="trackWidth"
          :r="radius"
          cx="0"
          cy="0"
        />
      </g>
    </svg>
    <svg
      class="circular-progress-indicator"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
    >
      <circle
        class="circular-progress-circle-progress"
        :stroke-dasharray="`${circumference}  ${circumference}`"
        :style="{ strokeDashoffset: strokeDashoffset }"
        fill="transparent"
        stroke="#333"
        :stroke-width="trackWidth"
        :r="radius"
        :cx="radius + trackWidth / 2"
        :cy="radius + trackWidth / 2"
      />
    </svg>
    <Txt v-if="!hideContent" as="span" weight="bold" class="circular-progress-text" :size="fontSize">
      <slot v-if="$slots.default" :progress="progress" />
      <span v-else>{{ progress }}%</span>
    </Txt>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Txt from '@/components/atoms/Txt.vue'

export default defineComponent({
  components: { Txt },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 100,
    },
    size: {
      type: Number,
      default: 128,
    },
    color: {
      type: String, // 'violet' | 'orange' | 'acid' | 'green' | 'blue' | 'red'
      default: undefined,
    },
    trackWidth: {
      type: Number,
      default: 5,
    },
    fontSize: {
      type: [String, Number],
      default: undefined,
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    circleSize() {
      return this.size - this.trackWidth
    },
    radius() {
      return this.circleSize / 2
    },
    progress() {
      return Math.round((this.modelValue / this.max) * 100);
    },
    circumference() {
      return this.radius * 2 * Math.PI
    },
    strokeDashoffset() {
      return this.circumference - (this.progress / 100) * this.circumference
    },
  },
});
</script>

<style lang="scss" scoped>
.circular-progress {
  @apply relative;

  svg {
    @apply absolute inset-0;
  }
}

.circular-progress-indicator {
}

.circular-progress-circle-track {
  @apply stroke-primary-100;

  .c-violet & {
    @apply stroke-soft-blue-100;
  }
  .c-orange & {
    @apply stroke-accent-100;
  }
  .c-acid & {
    @apply stroke-acid-100;
  }
  .c-green & {
    @apply stroke-green-100;
  }
  .c-blue & {
    @apply stroke-soft-blue-300;
  }
  .c-red & {
    @apply stroke-red-100;
  }
}

.circular-progress-circle-progress {
  @apply stroke-primary-500;
  @apply -rotate-90 origin-center;
  transition-property: stroke-dashoffset;
  @apply duration-300 ease-in-out;

  .c-violet & {
    @apply stroke-violet-500;
  }
  .c-orange & {
    @apply stroke-accent-500;
  }
  .c-acid & {
    @apply stroke-acid-700;
  }
  .c-green & {
    @apply stroke-green-500;
  }
  .c-blue & {
    @apply stroke-soft-blue-700;
  }
  .c-red & {
    @apply stroke-red-500;
  }
}

.circular-progress-text {
  @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  @apply text-primary-500;
}
</style>
