<template>
  <div class="user-sidebar relative">
    <div ref="sidebarContent" class="user-sidebar-content lg:my-[60px]">
      <Txt v-if="cardsTitle" as="h4" type="h4" class="user-sidebar-cards-title">{{ cardsTitle }}</Txt>
      <div ref="cards" class="user-sidebar-cards">
        <slot/>
      </div>
    </div>
    <!-- <div v-if="overflown">
      <div class="absolute bottom-5 z-49 w-full flex justify-center">
        <Icon name="chevronDownLarge" class="transition-transform" :class="reverseArrow ? '-rotate-180' : ''"></Icon>
      </div>
    </div> -->
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    cardsTitle: {
      type: String,
      default: undefined,
    }
  },

  data() {
    return {
      overflown: false,
      reverseArrow: false,
      resizeObserver: null,
    };
  },

  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.overflown = this.$refs.sidebarContent.clientHeight < entry.target.scrollHeight;
      }
    });
    this.resizeObserver.observe(this.$refs.cards);
    this.handleOverflow(this.$refs.sidebarContent);
    this.$refs.sidebarContent.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", () => {
      this.handleOverflow(this.$refs.sidebarContent);
    });
  },

  beforeUnmount() {
    this.resizeObserver.unobserve(this.$refs.cards);
  },

  methods: {
    handleScroll: function (el) {
      if (el)
        this.reverseArrow = Math.ceil(el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight && el.target.scrollTop > 0;
    },
    handleOverflow: function (el) {
      if (el)
        this.overflown = el.scrollHeight > el.clientHeight;
    }
  },
});
</script>

<style lang="scss" scoped>
.user-sidebar {
  @apply w-full;
  @apply flex flex-col h-full;
}

.user-sidebar-actions {
  @apply flex justify-end grid-cols-1 gap-3 auto-cols-auto grid-flow-col-dense;
  @apply mb-6;

  > * {
    @apply w-auto;
  }
}

.user-sidebar-content {
  @apply flex-grow;
  @apply overflow-y-auto;
}

.user-sidebar-cards {
  @apply grid gap-3;
}
</style>
