import { createRouter, createWebHistory } from 'vue-router';
import { default as routes, defaultPaths } from './routes';
import companyService from '@/services/company.service';
import { RolesBaseRoute } from '../common/constants';
import { eventBus } from '../plugins/eventBus';

// Create the router instance
const router = createRouter({
  history: createWebHistory(), // Replace `mode: 'history'` with this
  routes, // Your routes configuration
});

// Global beforeEach hook
router.beforeEach((to, from, next) => {
  companyService.ping(); // Perform the ping service
  next(); // Always call next() to resolve navigation
});

// Global beforeResolve hook
router.beforeResolve(async (to, from, next) => {
  // Emit the `route-changed` event
  eventBus.$emit('route-changed', to.path);

  try {
    // Retrieve the role-based route configuration
    const activeRole = (localStorage.getItem('activeRole') || '').split('_')[0];
    const roleBaseRoute = RolesBaseRoute[activeRole] || '';

    // Check if the route is allowed
    if (defaultPaths.includes(to.path) || to.path.toLowerCase().startsWith(roleBaseRoute)) {
      next();
    } else {
      // Redirect to the base route of the role
      next({ path: roleBaseRoute });
    }
  } catch (error) {
    console.error(error);
    // Emit a logout event on error
    eventBus.$emit('logout');
  }
});

export default router;