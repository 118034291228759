<template>
	<AnalyticsCard layout="vertical" :title="title" :chart-height="chartHeight">
		<template #chart>
			<BarChart
				:datasets="chart.datasets"
				:labels="chart.labels"
				show-x-border
				show-y-labels
				show-x-labels
				show-x-intra-lines
				show-y-intra-lines
				stacked
				horizontal
				:aspect-ratio="aspectRatio"
				:chart-options="options"
			/>
		</template>
	</AnalyticsCard>
</template> 

<script>
import { defineComponent } from 'vue';

import colors from '@/utils/colors'

export default defineComponent({
  data() {
      return {
          title: this.$t("Dashboard.Charts.RiskByLocation"),
          options: {},
          chart:{
              labels: [],
              datasets: [
                  {
                  label: '',
                  backgroundColor: colors['soft-blue'][500],
                  data: [],
                  sent: []
              }]
          },
      }
	},

  props: {
      aspectRatio: Number,
      chartHeight: Number,
      data: Array,
	},

  methods : {
      transformData(){
          for(const c of this.data){
              this.chart.labels.push(c.site);
              this.chart.datasets[0].data.push( ((c.n_clicked / c.n_sent) * 100).toFixed(2))
              this.chart.datasets[0].sent.push(c.n_sent)
          }
          this.setOptions()
      },
      setOptions(){
          this.options = {
              scales : {
                  x : {
                      ticks : {
                          callback: (val) => {
                              return val +'%'
                          }
                      }
                  }
              },
              plugins: {
                      tooltip: {
                          callbacks : {
                              label: (tooltipItem) => {
                                  return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}%`;
                              },
                          }
                      }
              }
          }
      }
	},

  mounted(){
      if(this.data && this.data.length >0){
          this.transformData();
      }
	},
});
</script>