<template>
	<AnalyticsCard layout="vertical" :title="title" :chart-height="200">
        <template #chart>
            <table class="clickers-heatmap">
                <caption class="sr-only">{{title}}</caption>
                <tr>
                    <th></th>
                    <th v-for="(h, index) in campaignsHeaders" :key="h+index" scope="col">{{h}}</th>
                </tr>
                <tr v-for=" (d , j) in aggregatedData" :key="j">
                    <th scope="row">{{d.label}}</th>
                    <td v-for="(v, k) in d.values" :key="k"> {{v}}% </td>
                </tr>
            </table>
        </template>
        <template #menu-items>
                <MenuItem v-for="opt in options" :key="opt" @click="showData(opt)">{{opt}}</MenuItem>
        </template>
    </AnalyticsCard>
</template> 

<script>
import { defineComponent } from 'vue';

// import colors from '@/utils/colors'

export default defineComponent({
  data() {
      return {
          title: this.$t("Analytics.Charts.ClickersByTag"),
          chartOptions: {},
          chart : {},
          aggregatedData : {},
          transformedData : {},
          campaignsHeaders: []
      }
	},

  props: {
      data: Object,
      options : Array
	},

  methods : {
      showData(tag){

          
          this.title = this.$t("Dashboard.Charts.ClickersByTag", { tag })
          this.aggregatedData = {}

          console.log(this.transformedData[tag])

          for(const d of this.transformedData[tag] ) {
              if(!this.aggregatedData[d.tag]){
                  this.aggregatedData[d.tag] = {
                      label : d.tag,
                      values : []
                  }
              }

              this.aggregatedData[d.tag].values.push(d.click_rate)
          }
      }
	},

  mounted(){
      for(const key of Object.keys(this.data) ){
          this.transformedData[key] = this.data[key].filter( d => d.tag != null)
          for(const d of this.transformedData[key]){
              d.click_rate  = ((d.clicked / d.sent)*100).toFixed(1)  
              if(!this.campaignsHeaders.includes(d.campaign)){
                  this.campaignsHeaders.push(d.campaign)
              }
          }
      }
      this.showData(this.options[0])

	},
});
</script>
<style scoped>
.clickers-heatmap td {
    padding:5px;
    font-size: 14px;
}
.danger {
    background-color: red;
}
</style>