<template>
  <prism-editor
    v-model="codeBase"
    class="cbg-editor min-w-[400px]"
    :highlight="highlighter"
    line-numbers
    @update:modelValue="updateCodebase"
  ></prism-editor>
</template>

<script>
import { defineComponent } from 'vue';

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

export default defineComponent({
  emits: ['update:modelValue'],

  components: {
    PrismEditor,
  },

  props: {
    code: {
      type: String,
      default: '',
    },
  },

  data: function () {
    return {
      codeBase: this.code,
    }
  },

  methods: {
    refresh(code) {
      this.codeBase = code;
    },
    updateCodebase(code) {
      this.$emit('update:modelValue', code)
    },
    highlighter(code) {
      return highlight(code, languages.js) // languages.<insert language> to return html with markup
    },
  },
});
</script>

<style>
/* required class */
.cbg-editor {
  background: #2d2d2d;
  color: #ccc;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
/* Custom Styling */
.prism-editor-wrapper .prism-editor__editor,
.prism-editor-wrapper .prism-editor__textarea {
  min-height: 400px;
}
</style>