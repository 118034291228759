<template>
  <div>
    <Modal v-model:open="LpModalState" :title="title" :trigger="trigger" footer-alignment="right" with-close-button full-height>
      <div class="w-full h-full px-2 py-2">
        <div class="flex flex-col h-full">
          <PageHead :title="previewPageTitle" />
          <Tabs :tab-nav="tabs" class="flex flex-col grow overflow-hidden">
            <template #tabContent-1>
              <div class="flex flex-col tab-content gap-y-4 pt-5 h-full">
                <div>
                  <FormGroup html-for="languageLPSelect" :label="$t('LandingPage.AvailableLanguages')">
                    <CGSelect
                      id="languageLPSelect"
                      v-model="previewLanguage"
                      :options="langOptions"
                      :placeholder="$t('General.SelectOption')"
                      @update:modelValue="changePreviewLanguage"
                    ></CGSelect>
                  </FormGroup>
                </div>

                <!-- Show Preview/Editor toggle button -->
                <div class="flex justify-end">
                  <CGButton ref="confirmDeleteLp" color="accent" class="ml-2 mb-3" :disabled="!canEditLP || langOptions.length <=1" @click="confirmDeleteLandingPageContent">
                    <template #prefix>
                      <Icon name="trash" />
                    </template>
                    {{ $t('LandingPage.DeleteLandingPageContent') }}
                  </CGButton>

                  <CGButton class="ml-2 mb-3" :disabled="!canEditLP" @click="showEditor = !showEditor">
                    <template #prefix>
                      <Icon v-if="showEditor" name="eye" />
                      <Icon v-else name="edit" />
                    </template>
                    {{ showEditor ? $t('LandingPage.ShowPreview') : $t('LandingPage.ShowEditor') }}
                  </CGButton>

                  <CGButton class="ml-2 mb-3" :disabled="disableSaveBodyButton" @click="saveLPBody">
                    {{ $t('General.Save') }}
                  </CGButton>
                </div>

                <!-- Landing page Preview/Editor -->
                <div v-if="codePreview" class="border overflow-auto grow min-h-[300px]">
                  <Editor
                    v-if="showEditor"
                    ref="lpEditorRef"
                    :code="codePreview"
                    :line-numbers="true"
                    @update:modelValue="updateEditorPreview"
                  ></Editor>
                  <iframe v-if="previewLg && !showEditor" :srcdoc="previewLg" class="w-full h-full"></iframe>
                </div>
              </div>
            </template>

            <template #tabContent-2>
              <div class="flex flex-col tab-content gap-y-4 pt-5 h-full">
                <div>
                  <!-- Landing page name -->
                  <Txt weight="bold" small class="mb-5">{{ $t('LandingPage.BasicInfo') }}</Txt>
                  <FormGroup html-for="landingPageName" :label="$t('LandingPage.Name')">
                    <CGInput id="landingPageName" v-model="landingPage.name" :disabled="!canEditLP">
                      <template #suffix>
                        <CGButton :disabled="disableSaveNameButton" @click="updateLandingPageInfo">
                          {{ $t('General.Save') }}
                        </CGButton>
                      </template>
                    </CGInput>
                  </FormGroup>
                </div>

                <hr />

                <div>  
                  <Txt small class="text-gray-700 mb-5">{{ $t('LandingPage.NewLandingPageLanguage') }}</Txt>
                  <FormGroup html-for="languageNewLPSelect" :label="$t('LandingPage.AvailableLanguages')">
                    <CGSelect
                      id="languageNewLPSelect"
                      v-model="newLandingPageLanguage"
                      :options="isoLanguageOptions"
                      erasable
                      :disabled="!canEditLP"
                    ></CGSelect>
                  </FormGroup>
                </div>
                <div class="flex justify-end">
                  <CGButton :disabled="!newLandingPageLanguage || !canEditLP" @click="addLandingPageLanguage()">
                    {{ $t('LandingPage.AddLandingPageContent') }}
                  </CGButton>
                </div>
              </div>
            </template>

            <template #tabContent-3>
              <div class="flex flex-col tab-content pt-5 h-full">
                <Txt small class="text-gray-700">{{ $t('LandingPage.AssignLPToCompany') }}</Txt>

                <div class="glow overflow-auto border my-4 px-3">
                  <div
                    v-for="(company, i) in companyList"
                    :key="'company'+i"
                    class="separator company py-2"
                  >
                    <fieldset class="flex flex-col space-y-2">
                      <Checkbox
                        v-model="company.assigned"
                        :disabled="!canEditLP"
                        :modelValue="company.assigned"
                        @update:modelValue="assignToCompany(company.company_id, $event)"
                      >
                        {{ company.name }}
                      </Checkbox>
                    </fieldset>
                  </div>
                </div>
                
                <!--<div class="flex justify-end">
                  <CGButton :disabled="disableAssignToCompanyButton" @click="saveAssignToCompanyInfo">
                    {{ $t('General.Save') }}
                  </CGButton>
                </div>-->
              </div>
            </template>

            <template #tabContent-4>
              <div class="flex flex-col tab-content gap-y-4 pt-5 h-full">

                <Txt weight="bold" small>{{ $t('LandingPage.Settings') }}</Txt>
                <Txt small class="text-gray-700">{{ $t('LandingPage.CaptureCredentialInfo') }}</Txt>
                
                <fieldset class="flex flex-col space-y-4">
                  <Checkbox
                    id="lpCaptureCredentials"
                    v-model="landingPage.capture_credentials"
                    :modelValue="1"
                    :unchecked-value="0"
                    :disabled="!canEditLP"
                  >
                    {{ $t('LandingPage.CaptureCredentials') }}
                  </Checkbox>
                </fieldset>
                
                <div v-if="landingPage.capture_credentials">
                  
                  <!-- Capture password -->
                  <Txt small class="text-gray-700">{{ $t('LandingPage.CapturePasswordInfo') }}</Txt>
                  <fieldset class="flex flex-col space-y-4 mt-5">
                    <Checkbox
                      id="lpCapturePassword"
                      v-model="landingPage.capture_passwords"
                      :modelValue="1"
                      :unchecked-value="0"
                      switch
                      size="lg"
                      :disabled="!canEditLP"
                    >
                      {{ $t('LandingPage.CapturePassword') }}
                    </Checkbox>
                  </fieldset>

                  <!-- Redirect users to URL -->
                  <Txt small class="mt-5 text-gray-700">{{ $t('LandingPage.RedirectToInfo') }}</Txt>
                  <FormGroup class="mt-4" html-for="lpRedirectTo" :label="$t('LandingPage.RedirectTo')">
                    <CGInput
                      id="lpRedirectTo"
                      v-model="landingPage.redirect_url"
                      :disabled="!canEditLP"
                      :placeholder="$t('LandingPage.RedirectTo')"
                    ></CGInput>
                  </FormGroup>
                </div>

                <div class="flex justify-end">
                  <CGButton :disabled="disableSaveSettingsButton" @click="updateLandingPageInfo">
                    {{ $t('General.Save') }}
                  </CGButton>
                </div>

                <div v-if="companyOptions && companyOptions.length" class="space-y-2">
                  <Txt weight="bold" small>{{ $t('LandingPage.CopyLandingPage') }}</Txt>
                  
                  <FormGroup :label="$t('LandingPage.CopyLandingPage')" html-for="copyLPCompanySelect" sr-only>
                    <CGSelect id="copyLPCompanySelect" v-model="copyLPCompanyId" :options="companyOptions"></CGSelect>
                  </FormGroup>
                  <div class="flex justify-end">
                    <CGButton @click="copyLandingPage">
                      {{ $t('General.Copy') }}
                    </CGButton>
                  </div>
                </div>
                
              </div>
            </template>
          </Tabs>
        </div>
      </div>
      <template #footer>
        <CGButton ref="cancelButton" variant="outline" @click="manageUnsavedInfo">{{ $t('General.Close') }}</CGButton>
      </template>
    </Modal>

    <!-- Landing page version deletion confirmation modal -->
    <Modal
      v-model:open="lpDeleteModalState"
      :title="$t('LandingPage.ConfirmDeleteTitle')"
      :trigger="$refs.confirmDeleteLp"
      footer-alignment="center"
      small
    >
      <div class="w-full h-32 bg-primary-100 text-primary-500 px-4 py-4 text-sm">
        <ul>
          <li>{{ $t('LandingPage.ConfirmDeleteContent', { param: isoLanguages[previewLanguage] }) }}</li>
          <li>{{ $t('General.IrreversibleConfirm') }}</li>
        </ul>
      </div>

      <template #footer>
        <CGButton variant="outline" @click="lpDeleteModalState = false">{{ $t('General.Cancel') }}</CGButton>
        <CGButton @click="lpDeleteModalState = false; deleteLandingPageContent()">
          {{ $t('General.Confirm') }}
        </CGButton>
      </template>
    </Modal>

    <!-- Modal for unsaved info -->
    <Modal v-model:open="unsavedLPInfoModalState" :title="$t('General.UnsavedInfo')" :trigger="$refs.cancelButton" footer-alignment="center" small>
      <div class="w-full h-32 bg-primary-100 text-primary-500 px-4 py-4 text-sm">
        <ul>
          <li>{{ $t('General.UnsavedInfoText') }}</li>
        </ul>
      </div>

      <template #footer>
        <CGButton variant="outline" @click="unsavedLPInfoModalState = false">{{ $t('General.Cancel') }}</CGButton>
        <CGButton @click="undoUnsavedChanges()">
          {{ $t('General.Close') }}
        </CGButton>
      </template>
    </Modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import LandingPageService from '@/services/landingpage.service'
import PhishingService from '@/services/phishing.service'

export default defineComponent({
  emits: ['landing_page_reload', 'landing_page_copied'],

  props: {
    title: {
      type: String,
      default: '',
    },
    lpage: {
      type: Object,
      default: null,
    },
    // landingPage: {
    //   type: Object,
    //   default: null,
    // },
    searchText: {
      type: String,
      default: '',
    },
    index: {
      type: String,
      default: null,
    },
    company: {
      type: Object,
      default: null,
    },
    previewHints: {
      type: String,
      default: '',
    },
    trigger: {
      default: null
    },
  },

  data() {
    return {
      tabs: [
        { name: this.$t('LandingPage.Preview'), isActive: true },
        { name: this.$t('LandingPage.Details'), isActive: false },
        { name: this.$t('LandingPage.Settings'), isActive: false },
        { name: this.$t('LandingPage.Advanced'), isActive: false },
      ],
      LpModalState: true,
      showEditor: false,
      previewLg: null,
      codePreview: null,
      currentLP: null,
      isoLanguages: [],

      landingPage: { ...this.lpage },
      companyData: {},

      previewLanguage: '',
      previewPageTitle: '',
      langOptions: [],
      companyList: [],
      lpContent: [
        {
          language: '',
          body: '',
        },
      ],
      currentLPContent: [
        {
          language: '',
          body: '',
        },
      ],
      newLandingPageLanguage: null,
      companyOptions: [],
      companyToSave: [],
      copyLPCompanyId: null,
      lpDeleteModalState: false,
      unsavedLPInfoModalState: false,
      canViewGlobalLP: this.$checkPermission(['phishing-view-all-landingpage', 'phishing-view-global-landingpage']),
      canEditLP: false
    }
  },

  computed: {
    landingPageId() {
      return this.landingPage.page_id
    },
    landingPageCompanyId() {
      return this.landingPage.company_id
    },
    disableSaveBodyButton() {
      if (!this.lpContent || !this.currentLPContent) {
        return true
      }
      if (!this.previewLanguage) {
        return true
      }
      let savedData = this.lpContent.find((lp) => {
        return lp.language == this.previewLanguage
      })
      let previousData = this.currentLPContent.find((lp) => {
        return lp.language == this.previewLanguage
      })
      return JSON.stringify(previousData.body) === JSON.stringify(savedData.body)
    },
    disableSaveNameButton() {
      return this.currentLP && this.landingPage && this.currentLP.name == this.landingPage.name
    },
    disableSaveSettingsButton() {
      return (
        this.currentLP &&
        this.landingPage &&
        this.currentLP.redirect_url == this.landingPage.redirect_url &&
        this.currentLP.capture_credentials == this.landingPage.capture_credentials &&
        this.currentLP.capture_passwords == this.landingPage.capture_passwords
      )
    },
    disableAssignToCompanyButton() {
      return this.companyToSave && this.companyToSave.length === 0
    },
    isoLanguageOptions() {
      return Object.entries(this.isoLanguages).map((item) => {
        return { value: item[0], label: item[1] }
      })
    },
    landingPageName() {
      return this.landingPage.name
    },
  },

  watch: {
    company: function (newVal) {
      this.companyData = newVal
    },
    lpage: function () {
      this.landingPage = this.lpage
    },
    LpModalState: function (status) {
      if (!status) {
        this.manageUnsavedInfo()
      }
    },
  },

  async mounted() {
    this.loadPreview()
    this.getLandingPageContent(this.landingPageId)
    // Save the Landing Page data in order to detach widget data model from saved data
    this.currentLP = Object.assign({}, this.landingPage)
    // Retrieve locale settings
    let locale = this.$i18n.locale
    // Retrieve translations for all languages
    this.isoLanguages = this.$isoCountriesLanguages.getLanguages(locale)
    this.supportMessage =
      this.companyData && this.companyData.contact
        ? this.$t('LandingPage.ContactUsLandingPage', { param: this.companyData.contact })
        : this.$t('LandingPage.ContactSupportLandingPage')
    
    this.previewPageTitle = `${this.lpage.page_id} - ${this.lpage.name}`
  },

  beforeUnmount() {
    this.LpModalState = false
  },

  methods: {
    show(value) {
      this.LpModalState = value
    },
    updateLandingPageInfo() {
      this.$eventBus.$emit('loading', true)

      if (!this.landingPage.capture_credentials) {
        this.landingPage.capture_passwords = 0
        this.landingPage.redirect_url = ''
      }

      LandingPageService.updateLandingPageInfo(this.landingPageCompanyId, this.landingPageId, this.landingPage)
        .then(() => {
          console.debug('LandingPage - UpdateLandingPageInfo success', this.landingPageId)
          this.alertVariant = 'success'
          this.alertMessage = this.$t('LandingPage.UpdateLandingPageInfoSuccess')
          this.previewPageTitle = `${this.landingPage.page_id} - ${this.landingPage.name}`

          this.currentLP = Object.assign({}, this.landingPage)
          this.$emit('landing_page_reload', this.landingPageId)
        })
        .catch((error) => {
          console.error('LandingPage - updateLandingPageInfo error', this.landingPageId, error)
          this.alertVariant = 'danger'
          this.alertMessage = this.$t('LandingPage.UpdateLandingPageInfoError')
        })
        .finally(() => {
          this.$eventBus.$emit('loading', false)
          this.$eventBus.$emit('show-alert', { title: this.alertMessage, variant: this.alertVariant })
        })
    },
    addLandingPageLanguage() {
      // If the choosen language has been previously added, return
      let lang = this.langOptions.filter((lang) => {
        return lang.value === this.newLandingPageLanguage
      })
      if (lang.length != 0) {
        console.debug('LandingPageModal - language already exists', this.newLandingPageLanguage)
        this.$eventBus.$emit('show-alert', { title: this.$t('LandingPage.AddExistingContentError'), variant: 'danger' })
        return
      }
      this.$eventBus.$emit('loading', true)
      LandingPageService.addLandingPageLanguage(this.landingPageCompanyId, this.landingPageId, this.newLandingPageLanguage, {
        body: '<html></html>',
      })
        .then(() => {
          console.debug('LandingPage - addLPLanguage success', this.landingPageId)
          this.alertVariant = 'success'
          this.alertMessage = this.$t('LandingPage.AddContentSuccess', { param: this.isoLanguages[this.newLandingPageLanguage]})
          this.getLandingPageContent(this.landingPageId)
        })
        .catch((error) => {
          console.error('LandingPage - addLPLanguage error', this.landingPageId, error)
          this.alertVariant = 'danger'
          this.alertMessage = this.$t('LandingPage.AddContentError')
        })
        .finally(() => {
          this.$eventBus.$emit('loading', false)
          this.$eventBus.$emit('show-alert', { title: this.alertMessage, variant: this.alertVariant })
          this.newLandingPageLanguage = null
        })
    },
    confirmDeleteLandingPageContent() {
      console.debug(
        'LandingPage - deleteLandingPageContent - open confirmation modal for ',
        this.landingPageId,
        this.previewLanguage
      )

      this.lpDeleteModalState = true
    },
    async deleteLandingPageContent() {
      LandingPageService.deleteLandingPageContent(this.landingPageCompanyId, this.landingPageId, this.previewLanguage)
        .then(() => {
          console.debug('LandingPage - confirmDeleteLandingPageContent success', this.landingPageId)
          this.getLandingPageContent(false)
        })
        .catch((err) => {
          console.error('LandingPage - confirmDeleteLandingPageContent error', err)
          this.$eventBus.$emit('show-alert', { title: this.$t('LandingPage.DeleteError'), variant: 'danger' })
        })
    },
    async getLandingPageContent(languageSet) {
      let result = await LandingPageService.getLandingPageContent(this.landingPageCompanyId, this.landingPageId)
      if (result.data && result.data.length > 0) {
        this.lpContent = result.data
      }

      this.langOptions = []
      this.lpContent.forEach((item) => {
        let text = this.$t('Languages.' + item.language)
        if (text.indexOf('.') != -1) {
          // If the language isn't in the Catalog, provide another translation
          text = this.isoLanguages[item.language]
        }
        this.langOptions.push({ value: item.language, label: text })
        const englishDefault = this.langOptions.find((l) => l.value == 'en')

        this.previewLanguage = this.previewLanguage == '' && englishDefault ? englishDefault.value : this.langOptions.length ? this.langOptions[0].value : this.previewLanguage
      })

      this.changePreviewLanguage(languageSet)

      this.currentLPContent = JSON.parse(JSON.stringify(this.lpContent))

      this.$eventBus.$emit('loading', false)
    },
    compilePreview(lpContent, highlightSearch, language, isPreview) {
      return new Promise((resolve, reject) => {
        try {
          let lp = lpContent.filter((t) => {
            return t.language == language
          })
          if (lp.length == 0) {
            lp = lpContent.filter((t) => {
              return t.language == this.$profile && this.$profile.language
            })
            if (lp.length == 0 && this.companyData && this.companyData.company) {
              lp = lpContent.filter((t) => {
                return t.language == this.companyData.company.default_language
              })
            }
            if (lp.length == 0) {
              lp = lpContent.filter((t) => {
                return t.language == 'en'
              })
              if (lp.length == 0) {
                lp = lpContent
              }
            }
          }

          lp = lp[0]
          const lpBody = lp.body; //this.$filters.sanitizeRawHtml(lp.body)
          let highlightedBody = lpBody;
          if (this.searchText && highlightSearch) {
            var re_search = new RegExp(this.searchText, 'gim')
            highlightedBody = lpBody.replace(re_search, (match) => {
              return `<span style="background: #ffc871;">${match}</span>`
            })
          }

          if (this.previewHints && isPreview) {
            // Replace hint placeholder
            let hintString = `<ul>`
            let hintCount = 0

            this.previewHints.forEach((hint) => {
              if (hintCount == 4) {
                return
              }
              if (hint.language == this.previewLanguage) {
                hintString += `<li><span class="hint">${hint.body}</span></li>`
                hintCount++
              }
            })

            hintString += `</ul>`
            highlightedBody = highlightedBody.replace(/{{.Top4HintsAsHtml}}/gim, hintString)

            let baseURL = this.companyData.gp_landing_url
            if (baseURL) {
              highlightedBody = highlightedBody.replace(/{{.BaseURL}}/gm, baseURL)
            }
          }

          resolve(highlightedBody)
        } catch (error) {
          reject(error)
        }
      })
    },
    assignToCompany(destCompanyId, assignAction) {
      // AssignAction can be true or false with the following meaning:
      // true => assign this Landing page to the given Company
      // false => remove the association between this Landing page and the given Company
      LandingPageService.assignLandingPage(this.landingPageCompanyId, this.landingPageId, destCompanyId, assignAction)
    },
    async loadPreview() {
      // retrieve all companies
      let result = await PhishingService.getPhishingCompanies()

      // filter only companies that can view the current user
      const usersCompanies = this.$profile.companies.map(c=>c.company_id);
      this.companyList = result.data.filter(c=>usersCompanies.includes(c.company_id)).sort((a, b) => {
        return ('' + a.name).localeCompare('' + b.name)
      });

      // add assigned property to each company
      this.companyList.map((c) => {
        c.assigned = (c.landing_page_id == this.landingPageId);
      });

      // set company options for copy dropdown
      this.canEditLP = !this.landingPageCompanyId? this.$checkPermission('phishing-edit-all-landingpage') : this.$checkPermission(['phishing-edit-all-landingpage', 'phishing-edit-landingpage'])
      
      this.companyOptions = []
      if (this.$checkPermission('phishing-edit-all-landingpage')) this.companyOptions.push({ value: null, label: this.$t('LandingPage.Global') })
      
      this.companyList.forEach((c) => {
        this.companyOptions.push({ value: c.company_id, label: c.name })
      })
      
      this.copyLPCompanyId = this.companyOptions.length? this.companyOptions[0].value : null;

      this.changePreviewLanguage()
    },
    assignPreviewLanguage(languageSet) {
      // Find the Landing page version written for locale language setting
      if (!languageSet) {
        this.previewLanguage = this.lpContent.find((element) => element.language == this.$i18n.locale)
          ? this.$i18n.locale
          : null
        if (this.previewLanguage == null) {
          // If the 'locale' version does not exist, try displaying the Landing page in English, otherwise pick the first available language
          this.previewLanguage = this.lpContent.find((element) => element.language == 'en')
            ? 'en'
            : this.lpContent[0].language
        }
      }
    },
    async changePreviewLanguage(languageSet) {
      this.assignPreviewLanguage(languageSet)

      this.previewLg = await this.compilePreview(this.lpContent, true, this.previewLanguage, true)
      this.codePreview = await this.compilePreview(this.lpContent, false, this.previewLanguage, false)
      if (this.$refs.lpEditorRef) this.$refs.lpEditorRef.refresh(this.codePreview);
    },
    async updateEditorPreview(code) {
      let savedData = this.lpContent.find((lp) => {
        return lp.language == this.previewLanguage
      })

      if (savedData.body != code) {
        if (this.saveTimeout) {
          clearTimeout(this.saveTimeout)
        }
        this.saveTimeout = setTimeout(this.updateLandingPage, 1000, code)
      }
    },
    async updateLandingPage(code) {
      this.lpContent.forEach((lp) => {
        if (lp.language == this.previewLanguage) {
          lp.body = code
        }
      })

      this.previewLg = await this.compilePreview(this.lpContent, true, this.previewLanguage, true)
      this.codePreview = await this.compilePreview(this.lpContent, false, this.previewLanguage, false)
    },
    
    saveAssignToCompanyInfo() {
      // AssignAction can be true or false with the following meaning:
      // true => assign this Landing page to the given Company
      // false => remove the association between this Landing page and the given Company
      this.companyToSave.forEach(async (element) => {
        await LandingPageService.assignLandingPage(this.landingPageCompanyId, this.landingPageId, element.company_id, element.assigned)
      })
      this.companyToSave = []
    },
    saveLPBody() {
      let savedData = this.lpContent.find((lp) => {
        return lp.language == this.previewLanguage
      })
      let previousData = this.currentLPContent.find((lp) => {
        return lp.language == this.previewLanguage
      })

      if (JSON.stringify(previousData.body) === JSON.stringify(savedData.body)) {
        return
      }

      LandingPageService.updateLandingPage(this.landingPageCompanyId, this.landingPageId, this.previewLanguage, { body: savedData.body })
        .then(async () => {
          console.debug('LandingPage - updateLandingPage success', this.landingPageId)
          this.alertVariant = 'success'
          this.alertMessage = this.$t('LandingPage.UpdateLandingPageSuccess')

          // Update saved Landing Page content
          this.currentLPContent = JSON.parse(JSON.stringify(this.lpContent))

          this.$emit('landing_page_reload', this.landingPageId)
        })
        .catch((error) => {
          console.error('LandingPage - updateLandingPage error', this.landingPageId, error)
          this.alertVariant = 'danger'
          this.alertMessage = this.$t('LandingPage.UpdateLandingPageError')
        })
        .finally(() => {
          this.$eventBus.$emit('loading', false)
          this.$eventBus.$emit('show-alert', { title: this.alertMessage, variant: this.alertVariant })
        })
    },
    undoUnsavedChanges() {
      // Restore original template data to trigger computed variables reloading
      this.currentLP = Object.assign({}, this.landingPage)
      this.currentLPContent = JSON.parse(JSON.stringify(this.lpContent))
      this.companyToSave = []

      // this.$refs.lpModal.hide();
      this.unsavedLPInfoModalState = false
      this.LpModalState = false
    },
    manageUnsavedInfo() {
      let modified = !(
        this.disableSaveNameButton &&
        this.disableSaveSettingsButton &&
        this.disableSaveBodyButton &&
        this.disableAssignToCompanyButton
      )
      if (modified) {
        // Open unsaved changes warning modal
        this.unsavedLPInfoModalState = true
        // Prevent Landing page modal from closing
        this.LpModalState = true
      } else {
        // this.$refs.lpModal.hide();
        this.LpModalState = false
        this.unsavedLPInfoModalState = false
      }
    },
    async copyLandingPage() {
      try {
        await LandingPageService.copyLandingPage(this.landingPageCompanyId, this.landingPageId, {
          name: this.landingPage.name,
          companyId: this.copyLPCompanyId,
        }) 
        
        this.$emit('landing_page_copied', this.$t('LandingPage.CopySuccess'), 'success')
      } catch (error) {
        this.$emit('landing_page_copied', this.$t('LandingPage.CopyError'), 'danger')
      } 
    },
  },
});
</script>
<style lang="scss" scoped>
  .tab-content {
    @apply overflow-auto ;
    &::-webkit-scrollbar {
      @apply appearance-none;
      // @apply w-3 #{!important};
      width: 0.75rem !important;
      @apply invisible;
    }
    &:hover, &:focus, &:active {
      &::-webkit-scrollbar {
        @apply visible
      }
      &::-webkit-scrollbar-track {
        @apply bg-transparent;
        @apply rounded-full;
        @apply my-5;
      }
      &::-webkit-scrollbar-thumb {
        @apply rounded-full;
        @apply bg-primary-300/50;
      }
      & {
        scrollbar-width: auto;
        scrollbar-color: rgb(131 135 154 / 0.5)  transparent;
        @apply scroll-my-3;
      }
    }
  }
</style>