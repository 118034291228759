<template>
	<div class="w-full h-full relative">
		<div class="overlay-txt">
			{{ $t('Dashboard.Charts.HideCyberScore') }}
		</div>
		<div class="overlay-css !opacity-20 h-full m-auto">
			<PieChart
				title=""
				:noInteractions="true"
				:datasets="datasets"
				:labels="labels"
				:chart-options="chartOptions"
				:show-legend="showLegend"
				:cutout-percentage="cutoutPercentage"
			/>
		</div>
	</div>
</template>
<script>
import { defineComponent } from 'vue';

import colors from '@/utils/colors';
export default defineComponent({
  props: {
      chartLabels: {
          type: Array,
          default: () => ["Label A", "Label B", "Label C", "Label D"]       
      },
      chartDatasets: {
          type: Array,
          default: () => [
              {
                  backgroundColor: [colors.green[700], colors.green[500], colors.accent[500], colors.red[500]],
                  data: [
                      15,
                      6,
                      10,
                      20
                  ]
              }
          ]
      }
	},

  data() {
      return {
          labels: this.chartLabels,
          datasets: this.chartDatasets,
          chartOptions: {
              circumference: 180,
              rotation: -90,
          },
          showLegend: true,
          cutoutPercentage: 0.75,
      }
	},
});
</script>
<style lang="scss">
.overlay-txt {
	color: #000000;
	top: 50px;
	z-index: 30;
	width: calc(100% - 31px);
	margin: 0 16px;
	height: auto;
	position: absolute;
	text-align: center;
	word-break: break-word;
	font-size: 25px;
	font-weight: bold;
}
</style>