<template>
  <div v-if="$company.created_by_company.customization_enabled || $company.customization_enabled">
    <img
      :src="($company.created_by_company.customization_enabled && $company.created_by_company.togglelogo) || ($company.customization_enabled && $company.togglelogo)"
      :alt="($company.created_by_company.customization_enabled && $company.created_by_company.displayname ) || ($company.customization_enabled && $company.displayname)"
      class="h-12 w-auto"
    />
  </div>
  <svg v-else xmlns="http://www.w3.org/2000/svg" aria-label="Cyberguru" role="img" viewBox="0 0 66 75">
    <path
      d="M28.425 63.508c-2.07-1.668-4.702-4.16-7.332-7.663-7.134-9.5-11.036-22.277-11.436-37.262 4.539-.727 11.056-1.468 18.78-1.468 6.228 0 11.661.48 15.91 1.047l8.373-8.368c-4.743-.952-13.378-2.3-24.283-2.3-16.862 0-28.308 3.224-28.308 3.224C-2.224 60.751 28.437 75 28.437 75s25.467-11.842 28.197-51.824L45.35 34.45c-1.761 7.803-4.737 14.666-8.925 20.518-2.825 3.948-5.736 6.722-8 8.54z"
      fill="currentColor"
    />
    <path
      fill="#FF9712"
      d="M55.041 10.294l-8.23 8.225-17.937 17.924-6.739-6.798-5.855 5.808 12.562 12.669 17.207-17.189 10.744-10.734 8.171-8.162L65.342 0z"
    />
  </svg>
</template>

<script>
</script>
