<template>
	<div>
        <div class="relative">
            <div class="overlay-text">
                <span class="outline">
                    {{ $t('Dashboard.Charts.HideCyberScore') }}
                </span>
            </div>
        </div>
		<div class="overlay-css">
            <LineChart
				:title="$t('Dashboard.CyberScoreTitle')"
				:datasets="datasets"
				:labels="labels"
				:chart-options="chartOptions"
				:show-legend="showLegend"
				:show-x-labels="showXLabels"
				:show-x-border="showXBorder"
				:show-x-intra-lines="showXIntraLines"
				:show-y-labels="showYLabels"
				:show-y-border="showYBorder"
				:show-y-intra-lines="showYIntraLines"
                />
        </div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
      return {
          labels: [
              "022022",
              "032022",
              "042022",
              "052022",
              "062022",
              "072022",
              "082022",
              "092022",
              "102022",
              "112022",
              "122022",
              "012023",
              "022023"
          ],
          datasets: [
              {
              label: 'Company',
              borderColor: 'black',
              data: [40, 39, 10, 40, 39, 70, 55, 45, 40, 50, 60, 70],
              },
          ],
          chartOptions: {
              scales: {
              y: {
                  min: 0,
              },
              },
          },
          showLegend: true,
          showXLabels: true,
          showXBorder: true,
          showXIntraLines: true,
          showYLabels: true,
          showYBorder: true,
          showYIntraLines: false,
      }
	},

  created() {
      this.labels = this.labels.map(m => this.$dateTime.fromFormat(m, 'MMyyyy').toFormat("LLL yy", {locale: this.$i18n.locale}));
  },
});
</script>

<style lang="scss">
.overlay-css {
	border-radius: .25rem;
	margin: 0 16px;
	opacity: 0.4;
}
.overlay-text {
	color: #808080;
    top: calc(100% - -180px);
    z-index: 30;
	width: calc(100% - 31px);
	margin: 0 16px;
	height: auto;
    position: absolute;
	text-align: center;
	word-break: break-word;
	font-size: 30px;
	font-weight: bold;
	.outline {
		-webkit-text-stroke: 1px #cac3c3;
		user-select: none;
	}
}
</style>