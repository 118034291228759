import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Create the i18n instance
const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'it', // Default locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'it', // Fallback locale
  messages: loadLocaleMessages(),
  globalInjection: true, // Enables `$t` globally
  silentTranslationWarn: false, // Suppress warnings for missing translations
});

export default i18n;