<template>
  <div>
    <Modal
        v-model:open='modalState' :title='title' :trigger="trigger" footer-alignment='right'
        :full-height='fullHeight' :small='small' :footer-visible='footerVisible' with-close-button>
      <Tabs v-if='filteredIndexes.length>1' :tab-nav='tabs' class='flex flex-col grow overflow-hidden'>
        <template v-for='(langIndex, indx) of filteredIndexes' :key='indx' v-slot:[`tabContent-${indx+1}`]>
          <div v-if='langIndex.name === "lang"' class='tab-content w-full h-auto'>
            <div class='flex flex-col gap-8 mt-6'>
              <div>
                <div class='mb-1'>
                  <Txt small weight='bold' class='mb-1'>{{ $t('Modules.AssociatedLang') }}</Txt>
                  <Txt small>{{ $t('Modules.AssociatedLangDesc') }}</Txt>
                </div>
                <div class='flex flex-col mt-4 border'>
                  <div v-for='(i , j) in presentLanguages' :key='j' class='flex justify-between items-center p-3'
                      :class="{ 'border-t' : j>0 }">
                    <div>
                      <Txt small>{{ langOptions.find(el => el.value == i).label }}</Txt>
                    </div>
                    <CGButton :ref="'confirmDelLang'+i" :disabled='isOnlyLanguage' variant='ghost' small color='light'
                            @click='confirmDeleteLanguage(i)'>
                      <Icon name='trash' />
                    </CGButton>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <Txt weight='bold' small class='mb-1'>{{ $t('Modules.AvailableLang') }}</Txt>
                  <Txt small>{{ $t('Modules.AvailableLangDesc') }}</Txt>
                </div>
                <div class='mt-4'>
                  <div v-if='!noLanguageAvailable' class='flex items-stretch'>
                    <FormGroup :label="$t('Modules.AvailableLang') " html-for="languageSelect" sr-only>
                      <CGSelect
                          id='languageSelect' v-model='selectedLanguage' :options='selectLanguage'
                          :disabled='noLanguageAvailable' erasable class='flex-grow'
                      />
                    </FormGroup>
                    <CGButton v-if='selectedLanguage' class='ml-4' @click='addTranslationLanguage( selectedLanguage)'>
                      {{ $t('Modules.AddLanguage') }}
                    </CGButton>
                  </div>
                  <div v-else>
                    <Txt small>{{ $t('Modules.NoLangAvailable') }}</Txt>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if='langIndex.name !== "lang"' class='tab-content w-full h-auto'>
            <div v-if='langIndex.name' class='grid gap-x-10 grid-cols-1' :class="{'sm:grid-cols-2':multipleColumns}">
              <FormGroup
                  v-for='(itemLang, indexLang) in dataItem[langIndex.name]' :key='indexLang'
                  :label='$t(itemLang.label)' :html-for='itemLang.id' no-margin
                  :class="{'mt-6' : itemLang.type !== 'hidden' }">
                <CGInput v-if="itemLang.type === 'text'" v-model='itemLang.translation' type='text' />
                <textarea
                    v-if="itemLang.type === 'textarea'" v-model='itemLang.translation' :rows='4' :cols='70'
                    class='block p-2.5 w-full text-sm text-gray-900 bg-white border border-gray-300 focus:border-soft-blue-100 focus:ring-soft-blue-100 read-only:border-gray-200'
                    :class="{'resize-none':itemLang.noResize}" />
              </FormGroup>
            </div>
          </div>
        </template>
      </Tabs>
      <template #footer>
        <CGButton @click='submitForm'>{{ $t('General.Save') }}</CGButton>
      </template>
    </Modal>
    <!-- MODALE CONFERMA RIMOZIONE LINGUA-->
    <Modal
        v-model:open="showConfirmDeletionModal"
        :title="$t('ContentManagement.RemoveConfirmModalLanguage')"
        :trigger="confirmDelLangRef"
        footer-alignment="right"
        small
    >
      <div class="w-full h-32 text-primary-500 px-4 py-4 text-sm">
        <Txt as='h6' medium style='padding-top: 3%' weight='medium'>
          <span>{{ confirmDeleteLanguageMessage }}</span><br>
          <span>{{ $t('General.IrreversibleConfirm') }}</span>
        </Txt>
      </div>
      <template #footer>
        <CGButton variant="outline" @click="showConfirmDeletionModal = false; noDeleteAction();">
          {{ $t('General.Cancel') }}
        </CGButton>
        <CGButton @click="showConfirmDeletionModal = false; deleteLanguage()">
          {{ $t('General.Confirm') }}
        </CGButton>
      </template>
    </Modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import localizationService from '@/services/localization.service'
import { Roles } from '@/common/constants'

export default defineComponent({
  emits: ['update'],

  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    },
    multipleColumns: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    footerVisible: {
      type: Boolean,
      default: true
    },
    ownerUuid : {
      type: String,
      default: null
    },
    trigger: {
      default: null
    },
  },

  data() {
    return {
      tabs: [],
      indexes: [],
      presentLanguages: [],
      selectedLanguage: null,
      langOptions: [],
      dataItem: [],
      translationUuids: [],
      newTranslation: {},
      itemTypeMap: {},
      translationArrayObj: [],
      selectOptionString: this.$i18n.t('Template.AllLanguages'),
      modalState: false,
      showConfirmDeletionModal: false,
      confirmDeleteLanguageMessage: '',
      confirmDelLangRef: null,
    }
  },

  computed: {
    filteredIndexes() {
      return this.indexes.map((value, i) => {
        return {
          id: i,
          name: value === 'lang' ? 'lang' : `${value}`
        }
      })
    },
    visibleFields() {
      return this.data.fields.filter(f => (!('display' in f) || f.display))
    },
    noLanguageAvailable() {
      return this.selectLanguage && this.selectLanguage.length === 0
    },
    selectLanguage() {
      return this.langOptions.filter(el => !this.presentLanguages.includes(el.value))
    },
    isOnlyLanguage() {
      return this.presentLanguages.length === 1
    }
  },

  mounted() {
    this.getLanguages()
  },

  methods: {
    show(value) {
      this.itemTypeMap = this.data.fields

      // console.debug(this.data.item, this.itemTypeMap)
      let item = []
      for (let [key, value] of Object.entries(this.data.item)) {
        item[key] = value
          .map(el => {
            el.lang_code = key
            if (this.itemTypeMap[el.table_field]) {
              el.label = this.itemTypeMap[el.table_field].label
              el.type = this.itemTypeMap[el.table_field].type
            }
            return el
          })
          .sort((a, b) => {
            if (this.itemTypeMap[a.table_field].order < this.itemTypeMap[b.table_field].order)
              return -1
            if (this.itemTypeMap[a.table_field].order > this.itemTypeMap[b.table_field].order)
              return 1
            return 0
          })
      }
      this.dataItem = item
      this.calculate()
      this.modalState = value
    },
    validate(value, field) {
      field.errored = false
      field.error = ''
      if (typeof field.validator == 'function' && value !== '') {
        this.formValid = field.validator(value)
        if (!this.formValid) {
          field.errored = true
          field.error = this.$t(field.errorMessage)
        }
      } else if (field.type === 'number' && 'min' in field && 'max' in field && value !== '') {
        if (Number(value) < field.min || Number(value) > field.max) {
          field.errored = true
          field.error = this.$t(field.errorMessage)
        }
      } else if (field.type === 'file') {
        this.fileError = false
      }
    },
    submitForm() {
      let arrayToSend = []

      for (let [key, value] of Object.entries(this.dataItem)) {
        console.debug(key && value)
        arrayToSend.push(...value)
      }

      localizationService.updateTranslation(arrayToSend, this.$props.ownerUuid || null)
      this.$emit('update')
      this.show(false)
    },
    calculate() {
      this.presentLanguages = []
      for (let [key] of Object.entries(this.dataItem)) {
        this.presentLanguages.unshift(key)
      }

      this.selectedLanguage = null
      this.indexes = ['lang', ...this.presentLanguages]
      this.tabs = [
        { name: this.$t('General.ManageLanguages').toString(), isActive: true, disabled: false },
        ...this.presentLanguages.map(el => ({
          name:     this.langOptions.find(l => l.value == el).label,
          isActive: false,
          disabled: false
        }))
      ]
      // console.debug("PostCalc",this.dataItem)

    },
    async addTranslationLanguage(lang) {
      this.dataItem[lang] =
        this.dataItem[this.presentLanguages[0]]
          .map(el => ({
            translation: '-',
            uuid:        el.uuid,
            lang_code:   lang,
            label:       el.label,
            type:        el.type,
            table_field: el.table_field,
          }))

      this.calculate()
    },
    confirmDeleteLanguage(lang) {
      if (this.isOnlyLanguage) return

      this.langToBDeleted = lang
      this.confirmDeleteLanguageMessage = this.$t('ContentManagement.RemoveConfirmModalLanguageMessage',
          { lang: this.langOptions.find(l => l.value == lang).label })
      this.confirmDelLangRef = this.$refs['confirmDelLang'+lang]
      this.showConfirmDeletionModal = true
    },
    async deleteLanguage(){
      const params = {}
      let lang = this.langToBDeleted
      if(this.$profile.hasRoles(Roles.CSM, Roles.MSP)) params.companyId = this.$currentCompany.company_id

      this.dataItem[lang].forEach((el) => localizationService.deleteTranslationLanguage(el.uuid, lang, params)
        .then(() => {
          let newDataItem = {}
          for (let [key, value] of Object.entries(this.dataItem))
            if (key != lang)
              newDataItem[key] = value

          this.dataItem = newDataItem
          this.calculate()
        }))
    },
    async noDeleteAction(){
      this.langToBDeleted = null
      this.confirmDeleteLanguageMessage = ''
      this.$eventBus.$emit('show-alert', { title: this.$t('General.Aborted'), variant: 'warning' })
    },
    async getLanguages() {
      localizationService.getLanguages().then((res) => {
        this.langOptions = res.data.map((el) => ({
          value: el.lang_code,
          label: el.name
        }))
      })
    }
  },
});
</script>
