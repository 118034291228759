<template>
  <div
    :class="{
      progress: true,
      'v-indeterminate': indeterminate,
      'c-primary': color === 'primary',
      'c-accent': color === 'accent',
      'c-violet': color === 'violet',
      'c-blue': color === 'blue',
      'c-red': color === 'red',
      'c-green': color === 'green',
    }"
  >
    <span
      class="progress-bar"
      :style="{ width: !indeterminate ? `${progress}%` : undefined }"
      role="progressbar"
      :aria-valuenow="modelValue"
      :aria-valuemin="0"
      :aria-valuemax="max"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String, // 'primary' | 'accent' | 'violet' | 'blue' | 'red' | 'green'
      default: 'primary',
    },
  },

  computed: {
    progress() {
      return Math.round((this.modelValue / this.max) * 100);
    },
  },
});
</script>

<style lang="scss" scoped>
.progress {
  --progress-color: ;
  @apply relative w-full h-[5px] overflow-hidden;
  @apply bg-primary-100;

  &.c-primary {
    --progress-color: theme('colors.primary.500');
    @apply bg-primary-500/10;
  }

  &.c-accent {
    --progress-color: theme('colors.accent.500');
    @apply bg-accent-500/10;
  }

  &.c-violet {
    --progress-color: theme('colors.violet.500');
    @apply bg-violet-500/10;
  }

  &.c-blue {
    --progress-color: theme('colors.soft-blue.700');
    @apply bg-soft-blue-500/10;
  }

  &.c-red {
    --progress-color: theme('colors.red.500');
    @apply bg-red-500/10;
  }

  &.c-green {
    --progress-color: theme('colors.green.500');
    @apply bg-green-500/10;
  }
}

.progress-bar {
  @apply absolute left-0 inset-y-0;
  background-color: var(--progress-color);

  .v-indeterminate & {
    @apply w-full;
    background: linear-gradient(to right, transparent 0%, var(--progress-color) 50%, transparent 100%);
    animation: rotate-gradient 2s cubic-bezier(0.2, 0.7, 0.7, 0.4) infinite;

    @keyframes rotate-gradient {
      0% {
        left: -100%;
      }
      100% {
        left: 100%;
      }
    }
  }
}
</style>
