<template>
  <div class="support-wrapper">
    <span v-if="!$company.created_by_company.customization_enabled && !$company.customization_enabled"><em>{{ $t('Support.Copyright', {currentYear: $dateTime.now().toFormat('yyyy')}) }}</em></span>
    <span>{{ $t('Support.Address', { 
      companyName: $company.created_by_company.customization_enabled ? $company.created_by_company.legal_company_name : ($company.customization_enabled ? $company.legal_company_name : CyberGuru.LegalName )
      ,VAT : $company.created_by_company.customization_enabled ? $company.created_by_company.vat : ($company.customization_enabled ? $company.vat : CyberGuru.VAT ),
      registeredOffice:  $company.created_by_company.customization_enabled ? $company.created_by_company.registered_office || '-' : ($company.customization_enabled ? $company.registered_office || '-' : CyberGuru.RegisteredOffice)
    }) 
      }}</span>
    <div class="mt-5">
      <span>{{ $t('Support.Mail') }} <a :href="mailTo">{{ placeholderMailTo }}</a></span>
    </div>
  </div> 
</template>

<script>
import { defineComponent } from 'vue';

import {CyberGuru} from "@/common/constants";

export default defineComponent({
  data() {
    return {
      CyberGuru,
      display: false
    };
  },

  computed: {
    mailTo() {
      return 'mailto:' + this.getCompanySupportMailInfo();
    },
    placeholderMailTo() {
      return this.getCompanySupportMailInfo();
    }
  },

  methods: {
    getCompanySupportMailInfo() {
      if (this.$company.company_options?.support_address?.value) {
        return this.$company.company_options?.support_address?.value
      } else if (this.$company.created_by_company.customization_enabled && this.$company.created_by_company.support_address) {
        return this.$company.created_by_company.support_address
      } else {
        return CyberGuru.SupportEmail;
      }
    }
  },
});
</script>

<style lang='scss' scoped>
.support-wrapper {
  @apply bg-custom-primary text-custom-contrastPrimary mb-10 flex flex-col p-8;
  & a {
    @apply underline
  }

  & span {
    @apply text-sm
  }
}
</style>