<template>
  <span ref="tooltipContainerEl" class="tooltip">
    <span
      ref="tooltipToggleEl"
      class="tooltip-toggle"
      @mouseenter="handleEnter"
      @mouseleave="handleLeave"
      @click="handleClick"
    >
      <slot />
    </span>

    <div
      v-if="open"
      ref="tooltipEl"
      :class="{
        'tooltip-popup': true,
        dark: true,
        open,
      }"
      class="!fixed"
      @mouseenter="handleEnter"
      @mouseleave="handleLeave"
    >
      <div ref="tooltipArrowEl" class="tooltip-arrow"></div>
      <CGButton v-if="showClose" class="tooltip-close" variant="ghost" color="accent" @click="handleClick">
        <Icon name="close" :size="30" />
      </CGButton>
      <div v-if="title" class="tooltip-title font-bold">{{ title }}</div>
      <div v-if="$slots.content" class="tooltip-content">
        <slot name="content" />
      </div>
    </div>
  </span>
</template>

<script>
import { defineComponent, nextTick, ref, onMounted, onUnmounted, watch } from 'vue';
import { createPopper } from '@popperjs/core';

export default defineComponent({
  props: {
    id: {
      type: String,
      default: undefined,
    },
    placement: {
      type: String, // 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'
      default: 'auto',
    },
    mode: {
      type: String, // 'hover' | 'click'
      default: 'hover',
    },
    title: {
      type: String,
      default: undefined,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    activeTooltipId: {
      type: String,
      default: null,
    }
  },
  setup(props, { emit }) {
    const open = ref(false);
    const tooltipContainerEl = ref(null);
    const tooltipToggleEl = ref(null);
    const tooltipEl = ref(null);
    const tooltipArrowEl = ref(null);
    let popperInstance = null;

    const initializePopper = () => {
      if (!tooltipToggleEl.value || !tooltipEl.value) return;
      if (popperInstance) popperInstance.destroy();
      popperInstance = createPopper(tooltipToggleEl.value, tooltipEl.value, {
        placement: props.placement,
        modifiers: [
          { name: 'offset', options: { offset: [0, 8] } },
          { name: 'arrow', options: { element: tooltipArrowEl.value } },
        ],
      });
    };

    const handleEnter = () => {
      if (props.mode !== 'hover') return;
      open.value = true;
      emit('set-active-tooltip', props.id);  // Emit event to notify parent
      nextTick(initializePopper);
    };

    const handleLeave = () => {
      if (props.mode !== 'hover') return;
      open.value = false;
      if (props.id === props.activeTooltipId) {
        emit('set-active-tooltip', null);  // Close this tooltip if it's the active one
      }
    };

    const handleClick = () => {
      if (props.mode !== 'click') return;
      open.value = !open.value;
      if (open.value) {
        emit('set-active-tooltip', props.id);  // Emit event to open this tooltip
        nextTick(initializePopper);
      } else {
        emit('set-active-tooltip', null);  // Close this tooltip if it's clicked to close
      }
    };

    const closeTooltip = () => {
      open.value = false;
      emit('set-active-tooltip', null);  // Close the tooltip when the close button is clicked
    };

    onMounted(() => {
      document.addEventListener('click', handleDocumentClick);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleDocumentClick);
      if (popperInstance) popperInstance.destroy();
    });

    const handleDocumentClick = (e) => {
      if (
        tooltipContainerEl.value &&
        !tooltipContainerEl.value.contains(e.target)
      ) {
        closeTooltip();
      }
    };

    // Watch for changes to activeTooltipId
    watch(() => props.activeTooltipId, (newVal) => {
      if (newVal !== props.id) {
        open.value = false;  // Close if it's not the active one
      }
    });

    return {
      open,
      tooltipContainerEl,
      tooltipToggleEl,
      tooltipEl,
      tooltipArrowEl,
      handleEnter,
      handleLeave,
      handleClick,
      closeTooltip,
    };
  },
});
</script>

<style lang="scss" scoped>
.tooltip {
  @apply relative;
  @apply text-sm;
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.tooltip-toggle {
  @apply inline-flex;
}

.tooltip-popup {
  @apply absolute;
  @apply w-screen max-w-xs z-20;
  @apply px-4 py-7;
  @apply bg-primary-500 text-white;
  @apply opacity-0 pointer-events-none;
  @apply transition-opacity duration-300 ease-in-out;

  &.open {
    @apply opacity-100 pointer-events-auto;
  }
}

.tooltip-title {
  @apply text-base leading-tight;
}

.tooltip-title + .tooltip-content {
  @apply mt-3;
}

.tooltip-close {
  @apply absolute right-2 top-2;
}

.tooltip-arrow {
  @apply absolute w-3 h-3 overflow-hidden;

  &::before {
    content: '';
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45;
    @apply w-3 h-3;
    @apply bg-primary-500 border border-primary-500;
  }

  .tooltip-popup[data-popper-placement^='bottom'] & {
    @apply -top-3 w-6;
    &::before {
      @apply top-full;
    }
  }

  .tooltip-popup[data-popper-placement^='top'] & {
    @apply -bottom-3 w-6;
    &::before {
      @apply top-0;
    }
  }

  .tooltip-popup[data-popper-placement^='left'] & {
    @apply -right-3 h-6;
    &::before {
      @apply left-0;
    }
  }

  .tooltip-popup[data-popper-placement^='right'] & {
    @apply -left-3 h-6;
    &::before {
      @apply left-full;
    }
  }
}
</style>
