<template>
  <Card
      :class="{
      'progress-card': true,
      's-large': large,
      'c-primary': color === 'primary',
      'c-orange': color === 'orange',
      'c-blue': color === 'blue',
      'c-violet': color === 'violet',
      'c-green': color === 'green',
      'c-red': color === 'red',
      'c-yellow': color === 'yellow',
      'c-inactive': color === 'inactive',
      'c-on-track': color === 'on-track',
      'c-regular': color === 'regular',
      'c-not-regular': color === 'not-regular',
      'c-bw': color === 'bw',
    }"
      :disabled="disabled"
      :to="to"
  >
    <div v-if="unlicensed && tooltip" class="unlicensed">
      <Tooltip placement="left">
        <Icon name="info" :size="30"/>
        <template #content>{{ tooltip }}</template>
      </Tooltip>
    </div>

    <div class="progress-card-content" :class="{ 'overlay-background': unlicensed }">
      <div class="progress-card-icon">
        <Icon :name="icon"/>
      </div>
      <div class="progress-card-details">
        <Txt class="progress-card-value" weight="bold">{{ modelValue }}</Txt>
        <Txt class="progress-card-title" extra-small :weight="readMoreText ? 'bold' : ''">{{ title }}</Txt>
        <div v-if="readMoreText">
          <Txt ref="description" extra-small :class="{'line-clamp-2': !readMore, 'text-xs': true}">{{ readMoreText }}</Txt>
          <div v-if="isTextClamped" class="mt-1.5">
            <CGButton variant="outline" color="light" extra-small @click="toggleText">{{ readMoreButtonText }}</CGButton>
          </div>
        </div>
      </div>
      <CGButton v-if="link && link.to" class="progress-card-value" variant="unstyled" :aria-label="ariaLabel" @click="!modelValue==0? $emit('click') : null">
        <Txt v-if="!link.icon" class="font-bold text-primary-700 text-xs">{{ link.text }}</Txt>
        <Icon v-if="link.icon && modelValue!=0" :name="link.icon" size="16"/>
      </CGButton>
    </div>
  </Card>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['click'],

  props: {
    title: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: '',
    },
    icon: {
      type: String,
      default: undefined,
    },
    color: {
      type: String, // 'primary' | 'orange' | 'blue' | 'violet' | 'green' | 'red' | 'yellow' | 'inactive' | 'on-track' | 'regular' | 'not-regular'
      default: undefined,
    },
    to: {
      type: String,
      default: undefined,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unlicensed: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      required: false,
      default: null
    },
    tooltip: {
      type: String,
      required: false,
      default: '',
    },
    readMoreText: {
      type: String,
      default: ''
    },
    ariaLabel: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      readMore: false,
      readMoreButtonText: this.$t('General.ReadMore'),
      isTextClamped: false,
    };
  },

  mounted() {
    for (let ref in this.$refs) {
      this.isTextClamped = this.$refs[ref].$el.scrollHeight > this.$refs[ref].$el.clientHeight;
    }
  },

  methods: {
    toggleText() {
      this.readMore = !this.readMore;
      this.readMoreButtonText = !this.readMore ? this.$t('General.ReadMore') : this.$t('General.Close');
    }
  },
});
</script>

<style lang="scss" scoped>
.overlay-background {
  opacity: 0.58;
}

.unlicensed {
  position: absolute;
  right: 24px;
  top: 22px;
  z-index: 9;
}

.progress-card {
  @apply h-auto;
}

.progress-card-content {
  @apply flex items-center;

  .s-large & {
    @apply flex-wrap;
  }
}

.progress-card-icon {
  @apply flex-shrink-0 flex items-center justify-center;
  @apply w-10 h-10 bg-primary-100 text-primary-500;
  @apply mr-4 text-2xl;

  .c-orange & {
    @apply bg-accent-100 text-accent-700;
  }

  .c-blue & {
    @apply bg-soft-blue-100 text-soft-blue-700;
  }

  .c-violet & {
    @apply bg-soft-blue-100 text-soft-blue-700;
  }

  .c-green & {
    @apply bg-green-100 text-green-700;
  }

  .c-red & {
    @apply bg-red-100 text-red-700;
  }

  .c-yellow & {
    @apply bg-yellow-100 text-yellow-700;
  }

  .c-inactive & {
    @apply bg-status-inactive text-status-inactive rounded-full h-4 w-4;
  }

  .c-on-track & {
    @apply bg-status-on-track text-status-on-track rounded-full h-4 w-4;
  }

  .c-regular & {
    @apply bg-status-regular text-status-regular rounded-full h-4 w-4;
  }

  .c-not-regular & {
    @apply bg-status-not-regular text-status-not-regular rounded-full h-4 w-4;
  }

  .c-bw & {
    @apply bg-white text-primary-700 border;
  }
}

.progress-card-details {
  @apply flex-1 flex flex-col;
  @apply space-y-1.5 px-0.5;

  .s-large & {
    @apply basis-full mt-5 space-y-0.5;
  }
}

.progress-card-value {
  @apply leading-none flex;
}

.progress-card-title {
  @apply text-primary-700 leading-none;
}
</style>

<style lang="scss" global>
.progress-card {
  .card-content {
    @apply px-6 py-4;
  }

  &.s-large {
    .card-content {
      @apply px-8 py-5;
    }
  }
}

/** TODO beware of this global style */
.tooltip-popup {
  width: calc(100vw - 100px) !important;
}

.tooltip-title {
  font-size: 0.8rem;
}
</style>
