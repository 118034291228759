<template>
  <component 
    :is="computedTag" 
    class="menu-item" 
    :class="{
      'font-normal': weight === 'normal',
      'font-medium': weight === 'medium',
      'font-bold': weight === 'bold'
    }" 
    role="menuitem"
    :to="to" 
    tabindex="0"
    variant="unstyled" 
    @click="$emit('click')">
    <Icon v-if="iconName" :name="iconName" />
    <slot />
  </component>
</template>

<script>
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router'; // Import RouterLink to handle the :to prop

export default defineComponent({
  props: {
    as: {
      type: String, // 'div' | 'button' | 'link'
      default: 'Link',
    },
    to: {
      type: [String, Object], // Support both String and Object for the :to prop (Vue Router)
      default: undefined,
    },
    iconName: {
      type: String,
      default: undefined,
    },
    weight: {
      type: String, // 'regular' | 'medium' | 'bold'
      default: undefined,
    }
  },

  emits: ['click'],

  computed: {
    computedTag() {
      // If 'to' is provided, use RouterLink; otherwise, use the 'as' prop or default 'Link'
      return this.to ? RouterLink : this.as;
    }
  }
});
</script>

<style lang="scss" scoped>
.menu-item {
  @apply appearance-none;
  @apply flex items-center text-left;
  @apply px-3.5 py-1.5;
  @apply min-w-[19rem];
  @apply hover:bg-soft-blue-300;
  @apply cursor-pointer;

  .icon {
    @apply text-[1.25em] mr-2 mb-1;
  }
}
</style>
