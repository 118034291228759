<template>
  <div :class="{ 'form-group relative': true, 'v-no-margin': noMargin, 'flex': horizontal, 'items-center': horizontal }">
    <label v-if="label" :for="htmlFor" :class="{ 'mr-4': horizontal, 'h-5': true, 'sr-only': srOnly }">{{ label }}
      <span v-if="required" class="inline align-top text-rose-600 text-2xl">*</span>
    </label>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: undefined,
    },
    htmlFor: {
      type: [String, Number],
      default: undefined,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    srOnly: {
      type: Boolean,
      default: false
    }
  },
});
</script>

<style lang="scss" scoped>
.form-group {
  & + &:not(.v-no-margin) {
    @apply mt-4;
  }

  label {
    @apply block text-sm text-primary-300;
  }
}
</style>
