<template>
  <div class="cta">
    <div v-if="$slots.prefix" class="cta-prefix">
      <slot name="prefix" />
    </div>

    <div class="cta-main">
      <Txt type="subtitle" weight="bold" no-margin class="cta-title">{{ title }}</Txt>
      <div class="cta-content">
        <slot />
      </div>
    </div>

    <div v-if="$slots.actions" class="cta-actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Txt from './Txt.vue'

export default defineComponent({
  components: { Txt },

  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.cta {
  @apply flex flex-col md:flex-row md:items-center;
  @apply px-7 py-5;
  @apply border border-primary-100;
}

.cta-prefix {
  @apply shrink-0;
  @apply mb-4 md:mb-0 md:mr-6;
}

.cta-main {
  @apply flex flex-col;
}

.cta-content {
  @apply flex flex-col max-w-xl;
  @apply text-primary-300 text-xs;
}

.cta-title {
  @apply mb-3 md:mb-2;
}

.cta-actions {
  @apply shrink-0;
  @apply mt-7 md:mt-0 md:ml-auto md:pl-6;
  @apply flex items-center space-x-3;
}
</style>
