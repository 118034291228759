<template>
	<div>
        <div class="relative">
            <div class="overlay-text">
                <span class="outline">
                    {{ $t('Dashboard.Charts.HideCyberScore') }}
                </span>
            </div>
        </div>
		<div class="overlay-css">
            <BarChart
              :datasets="datasets"
              :labels="labels"
              :show-legend="showLegend"
              :show-x-labels="showXLabels"
              :show-x-border="showXBorder"
              :show-x-intra-lines="showXIntraLines"
              :show-y-labels="showYLabels"
              :show-y-border="showYBorder"
              :show-y-intra-lines="showYIntraLines"
          />
        </div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

import colors from '@/utils/colors';
export default defineComponent({
  data() {
      return {
          labels: [
              "022022",
              "032022",
              "042022",
              "052022",
              "062022",
              "072022",
              "082022",
              "092022",
              "102022",
              "112022",
              "122022",
              "012023",
              "022023"
          ],
          datasets: [
          {
              type: 'line',
              tension: 0.5,
              label: this.$t('Progress.CompanyAverage'),
              backgroundColor: colors['violet'][500],
              borderColor: colors['violet'][700],
              borderWidth: 2,
              fill: false,
              data: [],
          },
          {
              type: 'bar',
              label: this.$t('Progress.IndividualScore'),
              backgroundColor: colors.accent[500] + '50',
              borderColor: colors.accent[500],
              borderWidth: 2,
              data: [],
          },
          {
              type: 'line',
              tension: 0.5,
              label: this.$t('Progress.TeamAverage'),
              backgroundColor: colors['green'][500],
              borderColor: colors['green'][700],
              borderWidth: 2,
              fill: false,
              data: [],
          },
          ],
          showLegend: true,
          showXLabels: true,
          showXBorder: true,
          showXIntraLines: true,
          showYLabels: true,
          showYBorder: true,
          showYIntraLines: false,
      }
	},

  created() {
      this.labels = this.labels.map(m => this.$dateTime.fromFormat(m, 'MMyyyy').toFormat("LLL yy", {locale: this.$i18n.locale}));
      this.datasets[0].data = [2, 3, 2, 3, 0, 3, 1, -0.3, -0.2, -0.1, -0.1, 1];
      this.datasets[1].data = [null, null, null, null, null, null, null, null, 2, 3, -3, 3];
      this.datasets[2].data = [null, null, null, null, null, null, null, -0.6, -0.5, -0.4, -0.4, 0];
  },
});
</script>

<style lang="scss">
.overlay-css {
	border-radius: .25rem;
	margin: 0 16px;
	opacity: 0.4;
}
.overlay-text {
    color: #808080;
    top: calc(100% - -120px);
    z-index: 999;
	width: calc(100% - 31px);
	margin: 0 16px;
	height: auto;
    position: absolute;
	text-align: center;
	word-break: break-word;
	font-size: 30px;
	font-weight: bold;
	.outline {
		-webkit-text-stroke: 1px #cac3c3;
		user-select: none;
	}
}
</style>