<template>
    <AnalyticsCard layout="vertical" :title="$t('Dashboard.Charts.ReliabilityScore')" :chart-height="chartHeight" :showLoader="isLoading">
        <template #chart>
            <LineChart
            v-if="chart.datasets[0].data?.length"
            :datasets="chart.datasets"
            :labels="chart.labels"
            show-x-labels
            show-y-labels
            show-x-intra-lines
            show-y-intra-lines
            dashed-lines
            :chart-options="options" 
            :aspect-ratio="aspectRatio"
            />
            <div v-else  class="!min-h-[13rem] flex font-bold text-xl">
                <div class="mx-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
            </div>
        </template>
    </AnalyticsCard>
</template>

<script>
import { defineComponent } from 'vue';

import phishingService from '@/services/phishing.service.js'
import colors from '@/utils/colors'
export default defineComponent({
  data() {
      return {
          isLoading: false,
          options: {},
          chartMode : 1,
          chart : {
              labels: [],
              datasets: [
                  {
                      label: '',
                      borderColor: colors['soft-blue'][500],
                      backgroundColor: colors['soft-blue'][500] + '50',
                      fill: true,
                      data: [],
                      sent: [],
                      clicked: [],
                      period: []
                  },
              ]
          },
          counter: 0
      }
  },

  props: {
      chartHeight: Number,
      aspectRatio: Number,
      apiFilter: Object,
      companyData: Object
  },

  watch: {
      '$props.apiFilter': {
          handler() {
              this.transformData();
          },
          deep: true
      }
  },

  mounted() {
      this.transformData()
  },

  methods: {
      async transformData(){
          try {
              this.counter++;
              this.isLoading = true;

              const result = await phishingService.getReliabilityScore(this.companyData.company_id, this.apiFilter)
              this.data = result.data || []

              this.chart.labels = []
              this.chart.datasets[0].data = []
              const d = [...this.data]
              d.reverse().forEach( c =>{
                  this.chart.labels.push(c.name);
                  this.chart.datasets[0].sent.push(c.sent);
                  this.chart.datasets[0].clicked.push(c.clicked);
                  let start = this.$dateTime.fromFormat(c.planned_start, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
                  let end = this.$dateTime.fromFormat(c.planned_start, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
                  this.chart.datasets[0].period.push(start + " - " + end);
                  if(this.chartMode){
                      this.chart.datasets[0].data.push(c.rel);
                  }else{
                      this.chart.datasets[0].data.push(c.rel_norm);
                  }
              })

              this.setOptions();
          } catch (error) {
              this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
          } finally {
              if (this.counter > 0) this.counter--;

              if (!this.counter) this.isLoading = false;
          }
      },
      setOptions() {
          this.options = {
              scales: {
                  x: {
                      gridLines: {
                          display: false
                      }
                  },
                  y: {
                      min: 0,
                      scaleLabel: {
                          display: true
                      }
                  }
              },
              responsive: true,
              plugins: {
                  tooltip: {
                      callbacks : {
                          title: (tooltipItem) => {
                              return this.$t('Dashboard.Charts.ReliabilityForCampaign', { campaign: tooltipItem[0].label });
                          },
                          label: (context) => {
                              return  this.$t('Dashboard.Charts.ReliabilityValue', { value : context.formattedValue})
                          },
                          afterBody: (tooltipItem) => {
                              
                              let body = []
                              // let datasetIndex = tooltipItem[0].datasetIndex;
                              let index = tooltipItem[0].dataIndex;
  
                              let sent = tooltipItem[0].dataset.sent[index];
                              let clicked = tooltipItem[0].dataset.clicked[index];
                              let period = tooltipItem[0].dataset.period[index];
                              
                              body.push("");
                              body.push(this.$t('Dashboard.Charts.AttacksSent') + ": " + sent);
                              body.push(this.$t('Dashboard.Charts.TotalClicks') + ": " + clicked);
                              body.push(this.$t('Dashboard.Charts.SuccessfulAttacks') + ": " + (100 * (clicked / sent)).toFixed(1) + "%");
                              body.push(this.$t('Dashboard.Charts.CampaignPeriod') + ": " + period);
                              
                              return body;
                          }
                      }
                  },
                  datalabels: {
                      formatter: function (_value) {
                          return _value > 0 ? parseFloat(_value).toFixed(1) : "";
                      },
                      clamp: true,
                      clip: true
                  }
              }
          };
      },
  },
});
</script>