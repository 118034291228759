<template>
  <div class="select-container">
    <label v-if="label" :for="id" class="sr-only">{{ label }}</label>
    <select
      :id="id"
      :value="modelValue"
      :class="{ 'v-auto-width': autoWidth }"
      :aria-label="label || placeholder"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option v-if="placeholder" value="">{{ placeholder }}</option>
      <option 
        v-for="option in options" 
        :key="option.value" 
        :value="option.value"
        :aria-label="option.label"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SimpleSelect',
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: undefined,
    },
    options: {
      type: Array,
      required: true
    },
    id: {
      type: [String, Number],
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue']
});
</script>

<style lang="scss" scoped>
.select-container {
  @apply flex relative;
  flex-direction: column;
}

.select-container select {
  appearance: none; /* Removes the default arrow styling */
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 2em !important; /* Adds space for the custom arrow */
  background: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2212%22 height=%2212%22 viewBox=%220 0 12 12%22%3E%3Cpath d=%22M2 4L6 8L10 4%22 stroke=%22%231F1584%22 stroke-width=%222%22 fill=%22none%22/%3E%3C/svg%3E') no-repeat right 0.75rem center;
  background-position: right 0.6em center; /* Adjust arrow position */
  background-size: 0.7em; /* Size of the arrow */

  @apply border border-primary-100;
  @apply bg-white;
  @apply h-10 pl-2 pr-8;
  @apply text-sm text-primary-700 placeholder:text-primary-300;
  outline: none;
  .disabled & {
    @apply bg-primary-100 text-primary-300 cursor-not-allowed;
  }

  &.active,
  &:focus {
    @apply border-soft-blue-500 ring-soft-blue-500;
  }

}
</style>
