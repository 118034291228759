<template>
  <div>
    <div id="ranking-list">
      <table :name="$t('Dashboard.TopList')" class="ranking-list">
        <tr>
          <component :is="'div'" class="ranking-list-item-content" variant="unstyled">
            <th>{{ $t('Progress.Rank') }}</th>
            <th class="ranking-list-name">{{ $t('Progress.Name') }}</th>
            <th class="ranking-list-stats">{{ $t('Progress.Points') }}</th>
          </component>
        </tr>
        <tr v-for="(entry, i) in entries" :key="i" class="ranking-list-item">
          <component :is="entry.link ? 'Link' : 'div'" v-if='!entry.emptyRow' class="ranking-list-item-content" variant="unstyled">
            <td class="ranking-list-pos">{{ position(entry, i) }}</td>
            <td v-if="entry.status != null">
              <div class="dot" :aria-label="userStatusLabel(entry.status)" :style="{ backgroundColor: setDotStatusColor(entry.status) }"></div>
            </td>
            <td v-if="showRisks()">
              <div class="square" :aria-label="riskGroupLabel(entry.risk)" :style="{ backgroundColor: setDotRiskColor(entry.risk) }"></div>
            </td>
            <span
              :class="{
              'ranking-list-name': true,
              'shadow-text': entry?.anon || false
             }"
             :aria-label="!entry.anon ? (!entry.name || entry.name == 'null'? $t('Analytics.NA') : entry.name) : $t('Progress.AnonName')">
              {{ !entry.anon ? (!entry.name || entry.name == 'null'? $t('Analytics.NA') : entry.name) : calcAnonName() + ' ' + calcAnonName() }}
            </span>
            <div class="ranking-list-stats">
              <ul class="ranking-list-stats-grid">
                <li v-for="(stat, ii) in entry.stats" :key="ii" class="ranking-list-stats-item">
              <span>
                <strong>{{ stat.label }}</strong>
                {{ stat.value }}
              </span>
                </li>
              </ul>
            </div>
            <div v-if="$slots.actions" class="ranking-list-actions">
              <slot name="actions" />
            </div>
          </component>
          <div v-else class="ranking-list-item-content ranking-anon-row">•••</div>
        </tr>

        <tr v-if="entries.length === 0" class="ranking-list-item">
          <td class="ranking-list-item-content ranking-anon-row h-20">{{ $t('Dashboard.Charts.NoData') }}</td>
        </tr>
      </table>
    </div>
    <div :class="{'ranking-list-legend': showRisks() && showStatus()}">
      <!-- legenda dei colori relativi agli stati utente -->
      <div v-if="showStatus()" id="ranking-keys-status" >
        <ul id="ranking-keys-status-list" class="p-4">
          <Txt small weight="bold" class="mb-3">{{ $t('Remediation.Legend') }}</Txt>
          <li v-for="item in statusColorsLegendObj" :key="item.name" class="flex">
            <div class="dot" :style="{ backgroundColor: item.color }"></div>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div v-if="showRisks()" id="ranking-keys-level">
        <ul id="ranking-keys-level-list" class="p-4">
          <Txt small weight="bold" class="mb-3">{{ $t('Remediation.Legend') }}</Txt>
          <li v-for="item in riskColorsLegendObj" :key="item.name" class="flex">
            <div class="square" :style="{ backgroundColor: item.color }"></div>
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Link from './Link.vue'
import colors from '@/utils/colors';
import { CgaStatusType, RiskGroup, ServiceType } from "@/common/constants";

export default defineComponent({
  components: { Link, colors },

  data() {
    return {
      riskColorsLegendObj:[
        {name: this.$t('Dashboard.Charts.Defender'), color: colors['soft-blue'][500]},
        {name: this.$t('Dashboard.Charts.Strong'), color: colors.green[500]},
        {name: this.$t('Dashboard.Charts.Intermediate'), color: colors.accent[500]},
        {name: this.$t('Dashboard.Charts.Weak'), color: colors.red[500]},
        {name: this.$t('Dashboard.Charts.UnratedClickers'), color: colors.phishing.unrated}
      ],
      statusColorsLegendObj:[
        {name: this.$t('General.InactiveUsers'), color: colors.status["inactive"]},
        {name: this.$t('General.OnTrackUsers'), color: colors.status["on-track"]},
        {name: this.$t('General.RegularUsers'), color: colors.status["regular"]},
        {name: this.$t('General.NotRegularUsers'), color: colors.status["not-regular"]}
      ]
    }
  },

  props: {
    entries: {
      type: Array, // Array<{ name: string, stats: { label: string, value: any }[], link?: string }>
      required: true,
    },
    page: {
      type: Number,
      required: false,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    },
    color_status: {
      type: String, // 'inactive' | 'on-track' | 'regular' | 'not-regular'
      default: undefined,
    }
  },

  methods:{
    position(e, i) {
      return typeof +e.positions === 'number' ? (e.position || (this.page - 1) * this.itemsPerPage + i + 1) + '°' : ''
    },
    calcAnonName() {
      const lenWord = Math.floor(Math.random() * 5) + 4, chars = {
        v: ['a', 'e', 'i', 'o', 'u'],
        c: ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'y', 'z']
      }
      let word = ''

      for (let i = 0; i < lenWord; i++)
        word += i % 2 === 0
          ? chars.c[Math.floor(Math.random() * chars.c.length)]
          : chars.v[Math.floor(Math.random() * chars.v.length)]

      return word
    },
    showStatus() {
      /** Mostra la legenda dei colori degli stati solo se nel dataset esiste il parametro
       * status riferito allo stato dell'utente in classifica */
      return this.entries.some(obj => Object.keys(obj).includes('status'));
    },
    showRisks() {
      return this.$profile.hasService(ServiceType.Phishing) && this.entries.some(obj => Object.keys(obj).includes('risk'));
    },
    setDotRiskColor(risk) {
      switch(parseInt(risk)) {
        case RiskGroup.Serial: 
          return colors.red[500];
        case RiskGroup.Frequent:
          return colors.accent[500];
        case RiskGroup.Rare:
          return colors.green[500];
        case RiskGroup.Defender:
          return colors['soft-blue'][500];
        default: 
          return colors.phishing.unrated;
      }
    },
    setDotStatusColor(status) {
      //status should be int value
      switch(parseInt(status)) {
        case CgaStatusType.NOTACTIVE:
          return colors.status['inactive']
        case CgaStatusType.ONLINE:
          return colors.status['on-track']
        case CgaStatusType.REGULAR:
          return colors.status['regular']
        case CgaStatusType.NOTREGULAR:
          return colors.status['not-regular']
      }
    },
    riskGroupLabel(risk) {
      switch(parseInt(risk)) {
        case RiskGroup.Serial: 
          return this.$t('Analytics.Table.SerialClickers');
        case RiskGroup.Frequent:
          return this.$t('Analytics.Table.FrequentClickers');
        case RiskGroup.Rare:
          return this.$t('Analytics.Table.RareClickers');
        case RiskGroup.Defender:
          return this.$t('Analytics.Table.DefenderUsers');
        default:
          return this.$t('Analytics.Table.UnratedClickers');
      }
    },
    userStatusLabel(status) {
      //status should be int value
      switch(parseInt(status)) {
        case CgaStatusType.NOTACTIVE:
          return this.$t('General.InactiveUser');
        case CgaStatusType.ONLINE:
          return this.$t('General.OnTrackUser');
        case CgaStatusType.REGULAR:
          return this.$t('General.RegularUser');
        case CgaStatusType.NOTREGULAR:
          return this.$t('General.NotRegularUser');
      }
    }
  },
});
</script>

<style lang="scss" scoped>
.ranking-list {
  @apply flex flex-col space-y-px;
  @apply text-xs;
}

.ranking-list-item {
}

.shadow-text {
  color: transparent;
  text-shadow: 0 0 5px #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ranking-anon-row {
  @apply justify-center;
  @apply font-bold;
  font-size: 16px;
}

.ranking-list-item-content {
  @apply bg-white;
  @apply relative;
  @apply flex flex-wrap items-center;
  @apply px-5 py-2.5;
  @apply hover:bg-soft-blue-300;
}

.ranking-list-pos {
  //@apply absolute left-3 top-1.5 md:top-1/2 md:-translate-y-1/2;
  @apply text-base font-bold;
  @apply w-12;
}

.ranking-list-name {
  @apply flex-1 order-1 truncate;
}

.ranking-list-actions {
  @apply order-2 md:order-3;
}

.ranking-list-stats {
  @apply basis-auto;
  @apply order-3 md:order-2;
  @apply text-right;
}

.ranking-list-stats-grid {
  @apply inline-grid gap-y-2 auto-cols-auto;
  grid-template-columns: repeat(4, minmax(0, auto));
  @apply md:grid-rows-1 md:grid-cols-none;
  @apply max-w-full overflow-hidden;
  @apply mx-0;

  > * {
    @apply md:row-start-1;
  }
}

.ranking-list-stats-item {
  @apply whitespace-nowrap;
  @apply md:border-l md:border-primary-300;
  @apply md:first:border-l-0;
}

.ranking-list-legend {
  @apply grid grid-cols-2 gap-2;
}

.dot {
  @apply w-4 h-4 rounded-full;
  @apply mr-4;
}

.square {
  @apply w-4 h-4;
  @apply mr-4;
}

ul#ranking-keys-status {
  @apply bg-white;
  @apply mt-2 space-y-1;
  li {
    @apply flex items-center;
    div {
      @apply rounded-full mr-1
    }
    span {
      @apply text-xs pt-0.5
    }
  }
}
</style>
