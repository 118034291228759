<template>
  <div
    :class="{
      checkbox: true,
      disabled,
    }"
  >
    <span class="checkbox-check" tabindex="0" @click="toggleChecked" @keydown.enter="toggleChecked">
      <input
        :id="id"
        class="checkbox-input"
        :checked="modelValue"
        type="checkbox"
        :name="name"
        :value="inputValue"
        :disabled="disabled"
        tabindex="-1"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <span class="checkbox-icon">
        <Icon v-if="modelValue" name="checkSmall" />
        <Icon v-else-if="indeterminate" name="minus" />
      </span>
    </span>
    <span class="checkbox-text" :for="id" @click="toggleChecked">
      <slot />
    </span>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from './Icon.vue'
export default defineComponent({
  emits: ['update:modelValue'],
  components: { Icon },

  props: {
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    modelValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    indeterminate: {
      type: Boolean,
      default: undefined,
    },
    inputValue: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: undefined,
    },
  },

  methods: {
    toggleChecked() {
      if (this.disabled) return
      this.$emit('update:modelValue', !this.modelValue)
    },
  },
});
</script>
<style lang="scss" scoped>
.checkbox {
  @apply inline-flex items-start;
  @apply text-sm;
  &:hover:not(.disabled) {
    .checkbox-input:not(:checked) + .checkbox-icon {
      @apply text-soft-blue-700;
    }
  }
  &.disabled {
    > * {
      @apply cursor-not-allowed;
      @apply text-primary-300;
    }
  }
}
.checkbox-check {
  @apply shrink-0;
  @apply flex items-center;
  height: calc(1em * 1.25);
}
.checkbox-icon {
  @apply block text-2xl;
  @apply relative w-5 h-5 pointer-events-none;
  @apply border border-primary-300;
  @apply transition-colors duration-150 ease-in-out;
  @apply text-primary-500;
  .icon {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
.checkbox-input {
  @apply sr-only;
  &:checked + .checkbox-icon {
    @apply bg-soft-blue-700;
    @apply border-soft-blue-700;
    @apply text-white;
    .disabled & {
      @apply bg-primary-300 border-primary-300;
    }
  }
}
.checkbox-text {
  @apply flex-grow;
  @apply ml-2;
  @apply cursor-default;
  font-size: inherit;
  line-height: inherit;
}
</style>
