<template>
        <AnalyticsCard layout="vertical" :title="$t('Dashboard.Charts.ClickRateByCampaign')" :chart-height="chartHeight" :showLoader="isLoading">
            <template #chart>
                <BarChart
                    v-if="!isLoading && clickRateByCampaign.datasets?.length && clickRateByCampaign.datasets[0].data.length"
                    :datasets="clickRateByCampaign.datasets"
                    :labels="clickRateByCampaign.labels"
                    show-x-border
                    show-y-labels
                    show-x-labels
                    show-x-intra-lines
                    show-y-intra-lines
                    :chart-options="options"
                    :aspect-ratio="aspectRatio"
                    />
                <div
                    v-if="!isLoading && clickRateByCampaign.datasets?.length && !clickRateByCampaign.datasets[0].data.length"
                    class="!min-h-[13rem] flex font-bold text-xl">
                    <div class="m-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
                </div>
            </template>
            <!-- feature temporary disabled -->
            <!-- <template #menu-items>
                <MenuItem @click="showAvgDiff('click-rate')">{{$t('Dashboard.Charts.ClickRateByCampaign')}}</MenuItem>
                <MenuItem @click="showAvgDiff('ml-vs-rand')">{{$t('Dashboard.Charts.MlVsRandomEfficiency')}}</MenuItem>
            </template> -->
        </AnalyticsCard>
</template> 

<script>
import phishingService from '@/services/phishing.service.js'
import colors from '@/utils/colors'
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
      return {
          isLoading: false,
          title: this.$t("Dashboard.Charts.ClickRateByCampaign"),
          options: {},
          averageDifficultyDataset: {},
          clickRateByCampaign : {},
          counter: 0
      }
	},

  props: {
      chartHeight : Number,
      aspectRatio: Number, 
      apiFilter: Object,
      companyData: Object
	},

  watch: {
      '$props.apiFilter': {
          handler() {
              this.transformData()
          },
          deep: true
      }
  },

  mounted(){        
      this.transformData();
	},

  methods : {
      initProps() {
          // init props
          this.averageDifficultyDataset = {
              type: 'line',
              label: '',
              backgroundColor: colors['green'][500],
              borderColor: colors['green'][700],
              borderWidth: 2,
              fill: false,
              data: [],
              sent: [],
              clicked : [],
              avg_difficulty : [],
              chartMode: 'avg-diff',
              options: {
                  responsive: true,
                  maintainAspectRatio: false
              },
              yAxisID: 'y1',
          };
          this.clickRateByCampaign = {
              datasets: [
                  {
                      label: '',
                      chartMode: 'click-rate',
                      backgroundColor: colors['soft-blue'][500],
                      data: [],
                      sent: [],
                      clicked : [],
                      avg_difficulty : [],
                      period: [],
                      hidden: false,
                  }
              ],
              labels: [],
              yAxisID: 'y',
          };

          this.setOptions();
      },
      showAvgDiff(showVal) { 
          if (showVal !== 'ml-vs-rand') {
              const selectedDataIndex = this.clickRateByCampaign.datasets.findIndex(dSet => dSet.chartMode == showVal)
              this.clickRateByCampaign.datasets[selectedDataIndex].hidden = !this.clickRateByCampaign.datasets[selectedDataIndex].hidden
          }
          this.$forceUpdate()
      },
      async transformData(){
          try {
              this.counter++;
              
              this.isLoading = true;
              const result = await phishingService.getCompanySummary(this.companyData.company_id, { code: 'campaign', ...this.apiFilter })
              const campaigns = [...result.data].slice().reverse()

              this.initProps();
          
              for(const c of campaigns){
                  this.clickRateByCampaign.labels.push(c.name)
                  this.clickRateByCampaign.datasets[0].data.push(((c.clicked/c.sent)*100).toFixed(1) )
                  this.clickRateByCampaign.datasets[0].sent.push(c.sent)
                  this.clickRateByCampaign.datasets[0].clicked.push(c.clicked)
                  this.clickRateByCampaign.datasets[0].avg_difficulty.push(c.avg_difficulty)
                  let start = this.$dateTime.fromFormat(c.planned_start, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
                  let end = this.$dateTime.fromFormat(c.planned_end, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
                  this.clickRateByCampaign.datasets[0].period.push(start + " - " + end);

                  this.averageDifficultyDataset.data.push(c.avg_difficulty)
                  this.averageDifficultyDataset.sent.push(c.sent)
                  this.averageDifficultyDataset.clicked.push(c.clicked)
                  this.averageDifficultyDataset.avg_difficulty.push(c.avg_difficulty)
              }
          } catch (error) {
              this.$eventBus.$emit('show-alert', {title: this.$t('General.DataFetchError'), variant: 'danger'});
          } finally {
              if (this.counter > 0) this.counter--;
              
              if (!this.counter) this.isLoading = false;
          }
      },
      setOptions(){
          this.options = {
              scales:{
                  y : {
                      min: 0,
                      ticks: {
                              callback: function (value) {
                                  return value + "%";
                              }
                          },
                  },
                  y1 : {
                      min: 0,
                      max: 1,
                      display: false,
                      position: 'right',
                  }
              },
              plugins: {
                  tooltip: {
                      callbacks : {
                          label: (tooltipItem) => {
                              if(tooltipItem.dataset.chartMode && tooltipItem.dataset.chartMode=='avg-diff'){
                                  return this.$t('Dashboard.Charts.AverageDifficulty')+ ": "+ tooltipItem.formattedValue
                              }else if(tooltipItem.dataset.chartMode && tooltipItem.dataset.chartMode=='avg-rand'){
                                  return this.$t('Dashboard.Charts.AverageProbabilityRandom')+ ": "+ tooltipItem.formattedValue
                              }else if(tooltipItem.dataset.chartMode && tooltipItem.dataset.chartMode=='avg-not-rand'){
                                  return this.$t('Dashboard.Charts.AverageProbabilityNotRandom')+ ": "+ tooltipItem.formattedValue
                              }else {
                                  return this.$t('Dashboard.Charts.SuccessfulAttacks') + ": " + tooltipItem.formattedValue + "%";
                              }
                          },
                          afterBody: (tooltipItem) => {
                              if(tooltipItem[0].dataset.chartMode == 'click-rate'){
                                  let body = []
                                  // let datasetIndex = tooltipItem[0].datasetIndex;
                                  let index = tooltipItem[0].dataIndex;
                                  let sent = tooltipItem[0].dataset.sent[index];
                                  let clicked = tooltipItem[0].dataset.clicked[index];
                                  let avg_difficulty = tooltipItem[0].dataset.avg_difficulty[index];
                                  let period = tooltipItem[0].dataset.period[index];
                                  
                                  body.push("");
                                  body.push(this.$t('Dashboard.Charts.AttacksSent') + ": " + sent);
                                  body.push(this.$t('Dashboard.Charts.Clicked') + ": " + clicked);
                                  body.push(this.$t('Dashboard.Charts.AverageDifficulty') + ": " + Number(avg_difficulty));
                                  body.push(this.$t('Dashboard.Charts.CampaignPeriod') + ": " + period);
                                  
                                  return body;
                              }
                          }
                      }
                  }
              }
          }
      }
	},
});
</script>