<template>
  <li
    v-if="visible"
    :class="{
      'contextual-menu-item': true,
      'with-progress': progress != null,
      'submenu-expanded': submenuExpanded,
      todo: progress === 0,
      done,
      active,
    }"
  >
    <router-link :to="to" class="contextual-menu-item-link" @click.capture="handleClick">
      <Txt as="span" class="contextual-menu-item-text" small>
        <slot />
      </Txt>
      <span v-if="done" class="contextual-menu-item-check">
        <Icon name="checkSmall" :size="24" />
      </span>
      <span v-if="progress != null && !$slots.submenu" class="contextual-menu-item-circular-progress">
        <CircularProgress :modelValue="progress" :size="18" :track-width="2" color="orange" hide-content />
      </span>
    </router-link>

    <ul
      v-if="$slots.submenu"
      ref="submenuEl"
      class="contextual-menu-item-submenu"
      :style="{
        height: submenuExpanded ? `${submenuHeight}px` : '0px',
      }"
    >
      <slot name="submenu" />
    </ul>
  </li>
</template>

<script>
import { defineComponent } from 'vue';

import Txt from './Txt.vue'
import Icon from './Icon.vue'
import CircularProgress from './CircularProgress.vue'

export default defineComponent({
  components: { Txt, Icon, CircularProgress },

  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: undefined,
    },
  },

  emits: ['click'],

  data() {
    return {
      submenuExpanded: this.active,
      submenuHeight: 0,
      visible: true
    }
  },

  mounted() {
    // Access submenu height
    this.submenuHeight = this.$refs.submenuEl ? this.$refs.submenuEl.scrollHeight : 0;

    // Check if the `submenu` slot exists and contains content
    if (!this.$slots.submenu) return;

    // Call the submenu slot function to get the rendered VNodes
    const submenuNodes = this.$slots.submenu();

    // Check if any of the submenu slot nodes contain data
    if (!submenuNodes.some(node => node.children)) {
      this.visible = false;
    }
  },

  methods: {
    handleClick(e) {
      this.$emit("click", this.to);

      if (this.$slots.submenu) {
        e.preventDefault()

        this.submenuExpanded = !this.submenuExpanded
        this.submenuHeight = this.$refs.submenuEl && this.$refs.submenuEl.scrollHeight
      }
    },
    expand() {
      setTimeout(() => {
        this.submenuHeight = this.$refs.submenuEl && this.$refs.submenuEl.scrollHeight
        this.submenuExpanded = true;
      }, 1000);
    }
  },
});
</script>

<style lang="scss" scoped>
.contextual-menu-item {
  @apply relative flex flex-col;
  @apply w-full;
}

.contextual-menu-item-link {
  @apply flex items-center;
  @apply px-5 py-0.5 h-10;
  @apply text-violet-700 font-bold;
  @apply lg:px-8;

  .todo & {
    @apply text-primary-300;
  }

  .active > & {
    @apply bg-soft-blue-300;
  }
}

.contextual-menu-item-text {
  @apply flex-1;
}

.contextual-menu-item-check {
  @apply flex;
  @apply text-green-500;
}

.contextual-menu-item-toggle {
  @apply shrink-0 -mr-1;
  @apply transition-transform duration-200 ease-in-out;

  .submenu-expanded & {
    @apply rotate-180;
  }
}

.contextual-menu-item-submenu {
  @apply h-0 overflow-hidden;
  @apply flex flex-col;
  @apply pl-10 lg:pl-8;
  @apply duration-300 ease-in-out;
  transition-property: height;

  .submenu-expanded & {
    @apply mb-3;
  }

  .active & {
    @apply lg:h-auto;
  }

  > .contextual-menu-item {
    @apply flex;

    > .contextual-menu-item-link {
      @apply font-normal;
    }

    &.active {
      > .contextual-menu-item-link {
        @apply font-bold underline;
        @apply bg-transparent;
      }
    }
  }
}

.contextual-menu-item-circular-progress {
  @apply pr-0.5;
}
</style>

<style lang="scss" global>
.contextual-menu-item-circular-progress {
  .circular-progress {
    .circular-progress-circle-track {
      @apply stroke-soft-blue-300;
    }
  }
}
</style>
