<template>
  <div>
    <div class="badge" :style="{ background: 'url('+bgImg+'), linear-gradient('+gradientDegrees+'deg, '+hexGradientFrom+', '+hexGradientTo+')', backgroundPosition: 'center' }">
      <div class="badge-content">
        <div class="header">
          <img :alt="title" :src="badgeImage">
          <Txt as="span">{{ $filters.date(badgeIssuedDate) }}</Txt>
        </div>
        <div class="body">
          <Txt as="h3">{{ title }}</Txt>
          <Txt as="span">{{ description }}</Txt>
        </div>
        <div class="footer">
          <Txt as="h3">{{ badgePoints }}</Txt>
          <Txt as="span">{{ $t('Badge.Points') }}</Txt>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Txt from '@/components/atoms/Txt.vue';

export default defineComponent({
  components: {
    Txt,
  },

  props: {
    bgImg: {
      type: String,
      default: undefined
    },
    hexGradientFrom: {
      type: String,
      default: "#010512",
    },
    hexGradientTo: {
      type: String,
      default: "#071036",
    },
    gradientDegrees: {
      type: Number,
      default: 180
    },
    badgeImage: {
      type: String,
      default: undefined,
    },
    badgeIssuedDate: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    badgePoints: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.badge {
  @apply aspect-square;

  .badge-content {
    @apply p-4 h-full text-white flex flex-col justify-between;

    .header {
      @apply flex items-center justify-between;

      img {
        @apply h-[48px];
      }

      span {
        @apply text-xs
      }
    }

    .body {
      .h3 {
        @apply text-xl font-black mb-0;
      }

      span {
        @apply text-xs
      }
    }

    .footer {
      @apply flex flex-col items-end;
      h3 {
        @apply text-5xl font-black mb-0;
      }

      span {
        @apply text-xs -mt-1
      }
    }
  }
}
</style>
