<template>
  <Modal
    v-model:open="localShowModal"
    footer-alignment="center"
    header-alignment="center"
    :with-close-button="false"
    :with-expand-button="false"
    :trigger="trigger"
    :title="$t('Wcag.PolicyModalTile')"
  >
    <div class="w-full bg-primary-100 text-primary-500 p-10 text-sm text-center">
      <Txt v-if="welcomeMessage" weight="bold" small no-margin>{{ welcomeMessage }}</Txt>
    </div>
    <div class="w-full">
      <p
        class="mx-20 my-5"
        v-html="
        $t('Login.WelcomeMessage', {
          termsLink: termsLink,
          policyLink: policyLink,
          linkStyle: 'download-link',
        })"
      ></p>
    </div>
    <template #footer>
      <CGButton @click="confirmPolicyAgreement">{{ $t('Login.WelcomeButton') }}</CGButton>
    </template>
  </Modal>
</template>
<script>
import { defineComponent } from 'vue';

import accountService from '@/services/account.service.js'
import localizationService from '@/services/localization.service.js'
import { CyberGuru } from '@/common/constants'

export default defineComponent({
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    trigger: {
      default: null
    }
  },

  data() {
    return {
      localShowModal: this.showModal,
      welcomeMessage: '',
      policyLink: null,
      termsLink: null,
      language: this.$i18n.locale
    }
  },

  watch: {
    showModal: function (newVal) {
      this.localShowModal = newVal
    }
  },

  async mounted() {
    const profileLang = this.$profile.language;
    const compLang = this.$company.default_language;

    // default language is only in english or italian!!!
    const defaultLang = profileLang == 'it' || compLang == 'it'? 'it' : 'en';
    // set privacy policy file url
    const privacyPolicyFiles = this.$company.file_status? this.$company.file_status['privacypolicy'] : null;
    if (privacyPolicyFiles) {
      this.policyLink = privacyPolicyFiles[profileLang]?
          `/common/company/${this.$user.company_id}/${profileLang}/privacypolicy.pdf` :
          `/common/company/${this.$user.company_id}/${compLang != profileLang? compLang : Object.keys(privacyPolicyFiles)[0]}/privacypolicy.pdf`;
    } else {
      this.policyLink = `/common/default/${defaultLang}/privacypolicy.pdf`
    }

    // set terms file url
    const termsFiles = this.$company.file_status? this.$company.file_status['termsandconditions'] : null;
    if (termsFiles) {
      this.termsLink = termsFiles[profileLang]?
          `/common/company/${this.$user.company_id}/${profileLang}/termsandconditions.pdf` :
          `/common/company/${this.$user.company_id}/${compLang != profileLang? compLang : Object.keys(termsFiles)[0]}/termsandconditions.pdf`;
    } else {
      this.termsLink = `/common/default/${defaultLang}/termsandconditions.pdf`
    }

    if (this.$profile && !this.$profile.policy_agreed) {
      const welcomeMessage = await this.getWelcomeMessage();
      this.welcomeMessage = welcomeMessage ? welcomeMessage : this.$t('Login.Welcome', { displayname: this.$company.created_by_company.customization_enabled ? this.$company.created_by_company.displayname : (this.$company.customization_enabled ? this.$company.displayname : CyberGuru.Name )});
    }
  },

  methods: {
    async confirmPolicyAgreement() {
      this.$eventBus.$emit('loading', true);
      accountService.updateProfileAccount(this.$user.id, this.$company.company_id, {policy_agreed: true})
          .then(() => {
            this.$profile.policy_agreed = true;
          })
          .catch(error => {
            console.log(error);
            this.$eventBus.$emit('show-alert', { title: this.$t('Profile.AccountModifyError'), variant: 'danger' });
          }).finally(() => {
        this.$eventBus.$emit('loading', false);
        this.localShowModal = false;
        window.location.reload()
      });
    },

    async getWelcomeMessage(){
      try {
        const result = await localizationService.getCompanyTranslation(this.$user.company_id, "LoginWelcome", this.$profile.language || this.language);
        return result?.data?.length && result.data[0].translation?.length ? result.data[0].translation : null;
      } catch (error) {
        console.error('Error Fetching Localized Message =>>', error);
        return null;
      }
    }
  }
});
</script>
