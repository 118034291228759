<template>
  <div @click="onClick">
    <div
      ref="wrapperEl"
      :class="{
        'input-wrapper': true,
        'v-unstyled-prefix': unstyledPrefix,
        'v-unstyled-suffix': unstyledSuffix,
        'v-errored': errored,
        's-large': large,
        active,
        disabled,
      }"
    >
      <span v-if="$slots.prefix" class="input-wrapper-prefix">
        <slot name="prefix" />
      </span>
      <span v-if="$slots.suffix" class="input-wrapper-suffix">
        <slot name="suffix" />
      </span>
      <div class="input-wrapper-content">
        <span v-if="label" class="input-wrapper-label" @click="onLabelClick">
          {{ label }}
        </span>
        <span class="input-wrapper-slot">
          <slot />
        </span>
      </div>
    </div>

    <span v-if="errorMessage" class="input-wrapper-error" role="alert">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    unstyledPrefix: {
      type: Boolean,
      default: false,
    },
    unstyledSuffix: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    errored: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['input', 'click'],

  methods: {
    onClick(e) {
      if (this.disabled) return
      this.$emit('click', e)
    },
    onLabelClick() {
      const input = this.$refs.wrapperEl.querySelector('input, select')
      if (input) {
        input.focus()
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.input-wrapper {
  @apply flex;
  @apply border border-primary-100;
  @apply bg-white;
  @apply text-sm;
  @apply ring-1 ring-transparent;
  @apply transition duration-200 ease-in-out;

  &.active,
  &:focus-within {
    @apply border-soft-blue-500 ring-soft-blue-500;
  }

  &.v-errored {
    @apply border-b-red-700;
  }

  &.disabled {
    @apply bg-primary-100 cursor-not-allowed;
  }

  &.s-large {
    @apply text-xs;

    input,
    select {
      @apply text-base h-12;
    }
  }

  :deep(input),
  select {
    @apply h-10;
    @apply pt-0.5; // font adjustment
  }
}

.input-wrapper-prefix,
.input-wrapper-suffix {
  @apply min-w-[2.5rem] h-10 shrink-0;
  @apply bg-primary-100;
  @apply flex items-center justify-center;

  .icon {
    @apply text-2xl;
  }

  .s-large & {
    @apply h-12 min-w-[3rem];
  }
}

.input-wrapper-prefix {
  @apply order-1;

  .v-unstyled-prefix:not(.disabled) & {
    @apply bg-transparent;
  }

  .v-unstyled-prefix & ~ input,
  .v-unstyled-prefix & ~ select {
    @apply pl-0;
  }
}

.input-wrapper-suffix {
  @apply order-3;

  .v-unstyled-suffix:not(.disabled) & {
    @apply bg-transparent;
  }

  .v-unstyled-suffix & ~ input,
  .v-unstyled-prefix & ~ select {
    @apply pr-0;
  }
}

.input-wrapper-content {
  @apply order-2;
  @apply flex-grow flex items-center;
  @apply max-w-full overflow-hidden;
}

.input-wrapper-label {
  font-size: inherit;
  @apply text-primary-300 cursor-default;
  @apply px-2;
  @apply pt-1; // font adjustment
}

.input-wrapper-slot {
  @apply flex-grow overflow-hidden;
}

.input-wrapper-error {
  @apply text-sm text-red-500;
  @apply mb-1; // font adjustment
  @apply mt-1;
}
</style>

<style lang="scss" global>
.input-wrapper-prefix,
.input-wrapper-suffix {
  .icon {
    @apply text-2xl;
  }
}
</style>
