<template>
	<AnalyticsCard layout="vertical" :title="title" :chart-height="chartHeight" :showLoader="isLoading">
		<template #chart>
			<LineChart
				v-if="weakUserChart.labels?.length"
				:datasets="weakUserChart.datasets"
				:labels="weakUserChart.labels"
				show-x-labels
				show-y-labels
				show-x-intra-lines
				show-y-intra-lines
				dashed-lines
				:chart-options="options"
				:aspect-ratio="aspectRatio"
			/> 
			<div v-else  class="!min-h-[13rem] flex font-bold text-xl">
                <div class="m-auto"> <Txt bold>{{ $t('Dashboard.Charts.NoData') }}</Txt> </div>
            </div>
		</template>
	</AnalyticsCard>
</template> 

<script>
import { defineComponent } from 'vue';

import colors from '@/utils/colors'
import { RiskGroup } from '@/common/constants'

export default defineComponent({
  data() {
      return {
          title: this.$t("Dashboard.Charts.WeakUsers"),
          options: {},
          weakUserChart: {}
      }
	},

  props: {
      chartHeight: Number,
      aspectRatio: Number,
      data: Object,
      isLoading: Boolean
	},

  watch: {
      $props: {
          handler() {
              this.transformData();
          },
          deep: true,
          immediate: true
      }
  },

  methods : {
      transformData(){
          this.weakUserChart = {
              datasets: [
                  {
                  label: this.$t('Dashboard.Charts.WeakUsers'),
                  borderColor: colors.red[500],
                  backgroundColor: colors.red[500] + '50',
                  fill: true,
                  data: [],
                  sent : [],
                  clicked: [],
                  period: []
                  },
              ],
              labels : []
          }
          const globalInfo = (this.data.globalInfo.length>0) ? [...this.data.globalInfo].slice().reverse() : []
          const statsInfo = (this.data.stats.length>0) ? [...this.data.stats].slice().reverse() : []
          globalInfo.forEach( c =>{
              this.weakUserChart.labels.push(c.name);
              let start = this.$dateTime.fromISO(c.planned_start, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
              let end = this.$dateTime.fromISO(c.planned_end, 'yyyy-MM-dd HH:mm:ss').toFormat('dd.MM.yyyy');
              this.weakUserChart.datasets[0].period.push(start + " - " + end);
          })
	
          statsInfo.forEach( c =>{
              let totalClick = 0 ; 
              let totalSent = 0 ; 
              c.forEach( group => {
                  totalClick+=group.clicked
                  totalSent+=group.sent
              })
              const weakUsers = c.filter( arr => arr.target_risk == RiskGroup.Serial ).reduce((sum, obj) => sum + obj.targets, 0);
              this.weakUserChart.datasets[0].data.push( weakUsers )
              this.weakUserChart.datasets[0].sent.push( totalSent )
              this.weakUserChart.datasets[0].clicked.push( totalClick )
          })
          this.setOptions();
      },
      setOptions(){
          this.options = {
              scales:{
                  y : {
                      min: 0
                  }
              },
              plugins: {
                  tooltip: {
                      callbacks : {
                          afterBody: (tooltipItem) => {
                              
                              let body = []
                              const index = tooltipItem[0].dataIndex;
                              const sent = tooltipItem[0].dataset.sent[index];
                              const clicked = tooltipItem[0].dataset.clicked[index];
                              let period = tooltipItem[0].dataset.period[index];
                              
                              body.push("");
                              body.push(this.$t('Dashboard.Charts.AttacksSent') + ": " + sent);
                              body.push(this.$t('Dashboard.Charts.TotalClicks') + ": " + clicked);
                              body.push(this.$t('Dashboard.Charts.SuccessfulAttacks') + ": " + (sent == 0 ) ? 0 : (100 * (clicked / sent)).toFixed(1)  + "%");
                              body.push(this.$t('Dashboard.Charts.CampaignPeriod') + ": " + period);
                              
                              return body;
                          }
                      }
                  }
              }
          }
      }
	},

  mounted(){
      this.transformData();
	},
});
</script>