<template>
  <li
    :class="{
      'navbar-item': true,
      active,
    }"
    @click="$emit('click')"
  >
    <span
      v-if="active"
      class="navbar-item-arrow"
      :style="{
        ['--arrow-color']: arrowColor,
      }"
    />
    <component
      :is="tag"
      :class="{
        'navbar-link': true,
        'navbar-button': !to,
        active,
      }"
      :to="to"
      :aria-label="ariaLabel"
      v-bind=hasPopup
    >
      <Icon v-if="icon" :name="icon" :size="24" :alt='$slots?.default[0]?.text' />
      <span class="navbar-link-text">
        <slot />
      </span>
    </component>
  </li>
</template>

<script>
import { defineComponent } from 'vue';

import Icon from './Icon.vue'
import colors from '@/utils/colors'

export default defineComponent({
  components: { Icon },

  props: {
    to: {
      type: String,
      default: undefined,
    },
    active: {
      type: Boolean,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    expanded: {
      type: Boolean,
      default: undefined,
    },
    expanding: {
      type: Boolean,
      default: undefined,
    },
    arrowColor: {
      type: String,
      default: colors['extra-light']
    },
    ariaLabel: {
      type: String,
      required: true
    },
    popup: {
      type: String,
      default: undefined
    }
  },

  emits: ['click'],

  computed: {
    tag() {
      return this.to ? 'router-link' : 'button'
    },
    hasPopup() {
      return this.popup ? { 'aria-haspopup': this.popup } : null;
    }
  },
});
</script>

<style lang="scss" scoped>
.navbar-item {
  @apply relative;
}

.navbar-link {
  @apply flex items-center space-x-3;
  @apply px-12 py-5 lg:px-2 lg:justify-center;
  //@apply text-base font-normal text-white;
  @apply text-base font-normal text-custom-contrastPrimary;
  @apply opacity-50 hover:opacity-90;
  @apply transition-opacity duration-300 ease-in-out;

  .active & {
    @apply font-bold opacity-100;
  }
}

.navbar-link-text {
  @apply lg:hidden;
}

.navbar-button {
  @apply w-full;
}

.navbar-item-arrow {
  --arrow-color: #fff;
  @apply absolute top-1/2 -translate-y-1/2;
  @apply left-0 lg:left-auto lg:right-0;
  @apply rotate-90 lg:-rotate-90;
  @apply block w-3 h-3;

  &::before {
    content: '';
    @apply absolute block w-0 h-0;
    @apply -left-0.5 bottom-0;
    @apply border-[8px] border-transparent;
    @apply border-t-transparent;
    border-bottom-color: var(--arrow-color);
  }
  &::after {
    content: '';
    @apply absolute block w-0 h-0;
    @apply border-[6px] border-transparent;
    border-bottom-color: var(--arrow-color);
  }
}
</style>

<!-- Navbar.vue references -->
<style lang="scss" global>
.navbar-expanded .navbar-link {
  @apply lg:px-12 lg:justify-start;
}

.navbar-footer-menu .navbar-link {
  @apply opacity-100;
}

.navbar-expanded .navbar-link-text {
  @apply inline;
}
</style>
