<template>
  <div class="pie-chart-wrapper">
    <ChartJSPie
      ref="chart"
      :class="{
        'pointer-events-none': noInteractions,
      }"
      :title="title"
      :chart-data="chartData"
      :dataset-id-key="datasetIdKey"
      :chart-options="config"
      :styles="{ width: '100%', height: '100%' }"
      :width="0"
      :height="0"
      tabindex="0"
      :aria-label="title"
      role="graphics-object"
      :aria-roledescription="$t('Wcag.PieChart')"
    />
    <div
      v-if="$slots.default"
      class="pie-chart-content"
      :style="{
        left: chartBounds ? `${chartBounds.left}px` : undefined,
        top: chartBounds ? `${chartBounds.top}px` : undefined,
        right: chartBounds ? `${chartBounds.right}px` : undefined,
        bottom: chartBounds ? `${chartBounds.bottom}px` : undefined,
        width: chartBounds ? `${chartBounds.width}px` : undefined,
        height: chartBounds ? `${chartBounds.height}px` : undefined,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted } from 'vue';
import { Pie as ChartJSPie } from 'vue-chartjs';
import { mergeObjects } from '@/utils/object';
import '@/utils/charts';

export default defineComponent({
  components: { ChartJSPie },

  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    chartOptions: {
      type: Object,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    dashedLines: {
      type: Boolean,
      default: false,
    },
    cutoutPercentage: {
      type: Number,
      default: 0.8,
    },
    noInteractions: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: undefined,
    },
  },

  setup(props) {
    const chart = ref(null);
    const chartBounds = ref({});
    
    const updateChartBounds = () => {
      if (chart.value && chart.value.$data._chart) {
        const chartArea = chart.value.$data._chart.chartArea;
        chartBounds.value = { ...chartArea };
      }
    };

    const chartData = computed(() => ({
      labels: props.labels,
      datasets: props.datasets.map((dataset) =>
        mergeObjects({ tension: 0.3, pointRadius: 0, pointHoverRadius: 0 }, dataset)
      ),
    }));

    const config = computed(() => {
      const defaultConfig = {
        responsive: true,
        aspectRatio: props.aspectRatio,
        maintainAspectRatio: !!props.aspectRatio,
        cutout: `${props.cutoutPercentage * 100}%`,
        plugins: {
          title: {
            display: !!props.title,
            text: props.title,
            padding: {
              bottom: 20,
            },
          },
          legend: {
            display: props.showLegend,
          },
        },
      };
      return mergeObjects(defaultConfig, props.chartOptions || {});
    });

    onMounted(() => {
      updateChartBounds();
    });

    watch(
      () => props,
      () => {
        updateChartBounds();
      },
      { deep: true }
    );

    return {
      chart,
      chartBounds,
      chartData,
      config,
      updateChartBounds,
    };
  },
});
</script>

<style lang="scss" scoped>
.pie-chart-wrapper {
  @apply relative w-full h-full z-10;
}

.pie-chart-content {
  @apply absolute inset-0;
  @apply flex items-center justify-center;
}
</style>
