<template>
  <button
    :class="{
      button: true,
      'c-primary': color === 'primary',
      'c-accent': color === 'accent',
      'c-secondary': color === 'secondary',
      'c-light': color === 'light',
      'c-green': color === 'green',
      'c-orange': color === 'orange',
      'c-yellow': color === 'yellow',
      'c-red': color === 'red',
      'c-custom-primary': color === 'custom-primary',
      'c-custom-secondary': color === 'custom-secondary',
      'v-fill': variant === 'fill',
      'v-outline': variant === 'outline',
      'v-ghost': variant === 'ghost',
      'f-circle': shape === 'circle',
      'f-square': shape === 'square',
      's-small': small,
      's-extra-small': extraSmall,
      's-large': large,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span v-if="$slots.prefix" class="button-prefix">
      <slot name="prefix" />
    </span>
    <span class="button-content">
      <slot />
    </span>
    <span v-if="$slots.suffix" class="button-suffix">
      <slot name="suffix" />
    </span>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    color: {
      type: String, // 'primary' | 'accent' | 'secondary' | 'light' | 'green' | 'orange' | 'red' | 'yellow' | 'custom-primary' | 'custom-secondary'
      default: 'primary',
    },
    variant: {
      type: String, // 'fill' | 'outline' | 'ghost'
      default: 'fill',
    },
    shape: {
      type: String, // 'circle' | 'square'
      default: undefined,
    },
    small: {
      type: Boolean,
      default: false,
    },
    extraSmall: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],
});
</script>

<style lang="scss" scoped>
.button {
  @apply relative;
  @apply inline-flex items-center justify-center;
  @apply text-sm font-bold;
  @apply transition-colors duration-200 ease-in-out;
  @apply disabled:cursor-not-allowed;

  &:not(.v-ghost) {
    @apply h-[42px] px-4;
  }

  &.s-extra-small {
    @apply text-xs;

    &:not(.v-ghost) {
      @apply h-6 px-2;
    }
  }

  &.s-small {
    &:not(.v-ghost) {
      @apply h-8;
    }
  }

  &.s-large {
    &:not(.v-ghost) {
      @apply h-[52px];
    }
  }

  &.f-circle{
    @apply w-[42px] h-[42px] px-0;

    &.s-small {
      @apply w-8 h-8;
    }
    &.s-large {
      @apply w-[60px] h-[60px];
    }

    .button-content {
      @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
    }
  }

  &.f-square {
    @apply rounded-[20px];
  }

  &.f-circle {
    @apply rounded-full;
    @apply shadow-lg shadow-black/10;
  }
}

.c-primary {
  &.v-fill {
    @apply bg-violet-700 dark:bg-white;
    @apply text-white dark:text-violet-700;
    @apply disabled:bg-primary-100 dark:disabled:bg-primary-100;
    @apply disabled:text-primary-500/60 dark:disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-primary-700 dark:hover:bg-light;
    }
  }

  &.v-outline {
    @apply border;
    @apply border-violet-700 dark:border-white;
    @apply text-violet-700 dark:text-white;
    @apply disabled:border-primary-300 dark:disabled:border-primary-300;
    @apply disabled:text-primary-300 dark:disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-soft-blue-100 dark:hover:bg-soft-blue-500;
    }
  }

  &.v-ghost {
    @apply text-violet-700 dark:text-white;
    @apply disabled:text-primary-300 dark:disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-primary-700 dark:hover:text-primary-100;
    }
  }
}

.c-accent {
  &.v-fill {
    @apply bg-accent-500;
    @apply text-white;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-accent-700;
    }
  }

  &.v-outline {
    @apply border;
    @apply border-accent-500;
    @apply text-accent-500;
    @apply disabled:border-primary-300;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-accent-100;
    }
  }
  &.v-ghost {
    @apply text-accent-500;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-accent-700;
    }
  }
}

.c-light {
  &.v-fill {
    @apply bg-extra-light;
    @apply text-primary-700;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-light;
    }
  }

  &.v-outline {
    @apply border border-primary-100;
    @apply text-primary-700;
    &:not(:disabled) {
      @apply hover:bg-extra-light;
    }
  }
}

.c-secondary {
  &.v-fill {
    @apply bg-primary-300;
    @apply text-white;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-primary-500;
      @apply dark:hover:bg-primary-100 dark:hover:text-primary-500;
    }
  }

  &.v-outline {
    @apply border border-primary-300;
    @apply text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-primary-100;
      @apply dark:hover:bg-primary-500;
    }
  }

  &.v-ghost {
    @apply text-primary-300 dark:text-primary-300;
    @apply disabled:text-primary-100 dark:disabled:text-primary-500;
    &:not(:disabled) {
      @apply hover:text-primary-500;
      @apply dark:hover:text-primary-100;
    }
  }
}

.c-green {
  &.v-fill {
    @apply bg-green-500;
    @apply text-white;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-green-700;
    }
  }
  &.v-outline {
    @apply border;
    @apply border-green-500;
    @apply text-green-500;
    @apply disabled:border-primary-300;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-green-100;
    }
  }
  &.v-ghost {
    @apply text-green-500;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-green-700;
    }
  }
}

.c-orange {
  &.v-fill {
    @apply bg-orange-500;
    @apply text-white;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-orange-700;
    }
  }
  &.v-outline {
    @apply border;
    @apply border-orange-500;
    @apply text-orange-500;
    @apply disabled:border-primary-300;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-orange-100;
    }
  }
  &.v-ghost {
    @apply text-orange-500;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-orange-700;
    }
  }
}

.c-yellow {
  &.v-fill {
    @apply bg-yellow-500;
    @apply text-white;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-yellow-700;
    }
  }
  &.v-outline {
    @apply border;
    @apply border-yellow-500;
    @apply text-yellow-500;
    @apply disabled:border-primary-300;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-yellow-100;
    }
  }
  &.v-ghost {
    @apply text-yellow-500;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-yellow-700;
    }
  }
}

.c-red {
  &.v-fill {
    @apply bg-red-500;
    @apply text-white;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-red-700;
    }
  }
  &.v-outline {
    @apply border;
    @apply border-red-500;
    @apply text-red-500;
    @apply disabled:border-primary-300;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-red-100;
    }
  }
  &.v-ghost {
    @apply text-red-500;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-red-700;
    }
  }
}

.c-custom-primary {
  &.v-fill {
    @apply bg-custom-primary;
    @apply text-custom-contrastPrimary;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-custom-primary;
    }
  }
  &.v-outline {
    @apply border;
    @apply border-custom-primary;
    @apply text-custom-contrastPrimary;
    @apply disabled:border-primary-300;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-custom-primary;
    }
  }
  &.v-ghost {
    @apply text-custom-contrastPrimary;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-custom-contrastPrimary;
    }
  }
}

.c-custom-secondary {
  &.v-fill {
    @apply bg-custom-secondary;
    @apply text-custom-contrastSecondary;
    @apply disabled:bg-primary-100 disabled:text-primary-500/60;
    &:not(:disabled) {
      @apply hover:bg-custom-secondary;
    }
  }
  &.v-outline {
    @apply border;
    @apply border-custom-secondary;
    @apply text-custom-contrastSecondary;
    @apply disabled:border-primary-300;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:bg-custom-secondary;
    }
  }
  &.v-ghost {
    @apply text-custom-contrastSecondary;
    @apply disabled:text-primary-300;
    &:not(:disabled) {
      @apply hover:text-custom-contrastSecondary;
    }
  }
}

.button-content {
  @apply whitespace-nowrap flex-grow text-ellipsis overflow-hidden;
  @apply inline-flex justify-center;
  @apply -mb-0.5; // font adjustment
}

.button-prefix,
.button-suffix {
  @apply inline-flex;
}

.button-prefix {
  @apply mr-1;
}

.button-suffix {
  @apply ml-1;
}
</style>

<style lang="scss" global>
.button {
  svg {
    @apply text-lg;
  }

  &:not(.s-extra-small) {
    svg {
      @apply text-2xl;
    }
  }
}
</style>
