<template>
  <Card class="risk-rating-card" :disabled="disabled">
    <div class="risk-rating-card-content">
      <CircularProgress class="risk-rating-card-progress" :modelValue="progress" :size="78" :color="progressColor">
        <template #default="{ progress: percent }">
          <div class="risk-rating-card-shield">
            <Icon
              :class="{
                'text-soft-blue-100': progressColor === 'violet' || progressColor === 'blue',
                'text-accent-100': progressColor === 'orange',
                'text-acid-100': progressColor === 'acid',
                'text-green-100': progressColor === 'green',
                'text-red-100': progressColor === 'red',
              }"
              name="shieldSolid"
              :size="56"
            />
            <Txt weight="bold" :size="14">{{ percent }}%</Txt>
          </div>
        </template>
      </CircularProgress>

      <div class="risk-rating-card-text">
        <Txt class="risk-rating-card-title" weight="bold">{{ title }}</Txt>
        <Txt v-if="description" class="risk-rating-card-description" small>{{ description }}</Txt>
      </div>
    </div>

    <template v-if="to" #footer>
      <CGLink class="risk-rating-card-link" :to="to" variant="unstyled">{{ linkLabel }}</CGLink>
    </template>
  </Card>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: 'Il tuo risk rating',
    },
    description: {
      type: String,
      default: undefined,
    },
    progress: {
      type: Number,
      required: true,
    },
    to: {
      type: String,
      default: undefined,
    },
    linkLabel: {
      type: String,
      default: 'Dettagli',
    },
    colorsConfig: {
      type: Object, // Colors are the same as CircularProgress: 'violet' | 'orange' | 'acid' | 'green' | 'blue' | 'red'
      default: () => ({
        0: 'violet',
        75: 'orange',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    progressColor() {
      const steps = Object.keys(this.colorsConfig).sort((a, b) => b - a)
      const index = steps.findIndex((step) => +step <= this.progress)
      const stepValue = index >= 0 ? steps[index] : steps[0]

      return this.colorsConfig[stepValue]
    },
  },
});
</script>

<style lang="scss" scoped>
.risk-rating-card {
}

.risk-rating-card-content {
  @apply flex flex-wrap;
  @apply -m-2.5;

  > * {
    @apply m-2.5;
  }
}

.risk-rating-card-progress {
  @apply shrink-0;
}

.risk-rating-card-text {
  @apply flex-1 basis-[200px];
}

.risk-rating-card-title {
}

.risk-rating-card-description {
  @apply mt-1.5;
}

.risk-rating-card-link {
  @apply text-sm text-violet-700 font-medium;
  @apply underline hover:no-underline;
}

.risk-rating-card-shield {
  @apply w-full h-full relative;

  > * {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
</style>
