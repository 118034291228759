<template>
  <div ref="containerEl" class="menu" @click="toggleOpen" @keydown.esc="open = false"
    @keydown.up.prevent="handleArrowKey" @keydown.down.prevent="handleArrowKey" @focusout="onFocusOut">
    <div ref="menuToggle" class="menu-button" :class="{ 'pointer-events-none opacity-30': !isMenuFull}">
      <slot name="button" />
    </div>
    <div
      id="menuTray"
      ref="menuItems"
      :class="{
        'menu-items-wrapper': true,
        open,
        '!fixed': isFixed,
        'hidden': !open
      }"
      role="menu"
    >
      <div ref="menuItemsList" class="menu-items" @focusout="onFocusOut">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

import { popperGenerator } from '@popperjs/core/lib/popper-base';
import offset from '@popperjs/core/lib/modifiers/offset';
import popperOffsets from '@popperjs/core/lib/modifiers/popperOffsets';
import computeStyles from '@popperjs/core/lib/modifiers/computeStyles';
import applyStyles from '@popperjs/core/lib/modifiers/applyStyles';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import eventListeners from '@popperjs/core/lib/modifiers/eventListeners';

export default defineComponent({
  props: {
    placement: {
      type: String,
      default: 'auto-start', // 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start'
    },
    isFixed: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      open: false,
      popper: null,
      isMenuFull: false,
      focusedIndex: -1,
    }
  },

  watch: {
    open(isOpen) {
      if (isOpen) {
        document.documentElement.addEventListener('click', this.handleClickOutside, { capture: true });
      } else {
        document.documentElement.removeEventListener('click', this.handleClickOutside);
      }
    }
  },

  mounted() {
    this.isMenuFull = this.$refs.menuItemsList.querySelectorAll("[role='menuitem']").length;
    
    // Ensure that popper is initialized in the correct context of this
    nextTick(() => {
      const createPopper = popperGenerator({
        defaultModifiers: [
          offset, popperOffsets, computeStyles, applyStyles, flip, preventOverflow, eventListeners
        ],
      });

      // Initialize popper with correct references
      this.popper = createPopper(this.$refs.menuToggle, this.$refs.menuItems, {
        placement: this.placement,
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 8,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      });

      // Event listener to update popper position when 'loading' event is fired
      this.$eventBus.$on('loading', value => {
        setTimeout(() => {
          if (this.popper && !value) this.popper.update();
        }, 50);
      });
    });
  },

  methods: {
    toggleOpen() {
      this.open = !this.open;
      if (this.popper) {
        this.popper.update(); // Update popper on open/close
      }
    },
    handleClickOutside(e) {
      if (!this.$refs.containerEl) return;
      if (!this.$refs.containerEl.contains(e.target)) {
        this.open = false;
      }
    },
    onFocusOut(event) {
      if (
        this.open &&
        (!this.$refs.menuItemsList?.contains(event.relatedTarget) || !this.$refs.containerEl?.contains(event.relatedTarget))
      ) this.open = false;
    },
    handleArrowKey(event) {
      if (!this.open) return;

      const menuItems = this.$refs.menuItemsList.querySelectorAll('.menu-item:not([disabled])');
      if (event.key === 'ArrowDown' && this.focusedIndex < menuItems.length - 1) {
        this.focusedIndex++;
      } else if (event.key === 'ArrowUp' && this.focusedIndex > 0) {
        this.focusedIndex--;
      } else {
        this.focusedIndex = this.focusedIndex === 0 ? menuItems.length - 1 : 0;
      }
      menuItems[this.focusedIndex].focus();
    },
  },
});
</script>

<style lang="scss" scoped>
.menu {
  @apply relative inline-flex;
}

.menu-button {
  @apply inline-flex;
}

.menu-items-wrapper {
  @apply pointer-events-none z-20;

  &.open {
    @apply pointer-events-auto;
  }
}

.menu-items {
  @apply z-20 overflow-hidden;
  @apply bg-extra-light text-sm;
  @apply shadow-md shadow-violet-500/20;
  @apply opacity-0;
  @apply transition duration-300 ease-in-out;

  [data-popper-placement^='right'] & {
    @apply -translate-x-2;
  }

  [data-popper-placement^='left'] & {
    @apply translate-x-2;
  }

  [data-popper-placement^='top'] & {
    @apply translate-y-2;
  }

  [data-popper-placement^='bottom'] & {
    @apply -translate-y-2;
  }

  .open & {
    @apply opacity-100 translate-y-0 translate-x-0;
  }

  hr {
    @apply border-violet-100 my-1.5;
  }
}
</style>
